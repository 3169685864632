import { CustomFlowbiteTheme } from 'flowbite-react';

const theme: CustomFlowbiteTheme = {
  theme: {
    navbar: {
      root: {
        base: 'bg-mogok-red px-4 dark:border-gray-700 dark:bg-gray-800 md:px-8 flex relative',
        inner: {
          base: 'mx-auto w-full flex flex-wrap items-center justify-between',
        }
      },
      collapse: {
        base: 'min-h-full pt-32 pb-16 absolute top-0 left-0 right-0 flex items-center justify-center bg-mogok-red lg:p-0 lg:flex lg:w-auto lg:relative',
        list: 'nav_bar_ja max-w-[200px] mt-4 navLink flex flex-col items-center justify-center font-serif md:mt-0 lg:max-w-full lg:font-sans lg:flex-row md:text-base gap-8 md:gap-8',
        hidden: {
          on: '',
        },
      },
      link: {
        base: 'block md:p-0 text-white flex h-full items-center text-xl lg:text-base',
        active: {
          on: 'text-white font-bold text-white transition-all duration-300',
          off: 'hover:text-white lg:hover:text-mogok-deep-red',
        },
      },
      toggle: {
        base: 'inline-flex items-center p-2 text-base text-white lg:hidden relative z-10',
        icon: 'h-6 w-6 shrink-0',
      },
    },
    footer: {
      root: {
        base: 'w-full rounded-lg bg-white md:flex md:items-center md:justify-between px-4 md:px-12 pt-4 md:pt-20 pb-2 md:pb-6',
      },
      groupLink: {
        link: {
          base: 'last:mr-0 md:mr-0',
          href: 'font-serif text-xl text-mogok-red ml-0 transition-all duration-300 hover:ml-4 hover:text-mogok-deep-red',
        },
      },
      title: {
        base: 'text-base font-medium text-mogok-red',
      },
    },
    // TODO: putting inside our outside root, this cause issues depending on platform (black border on white theme) - duplicated for root and non-root
    card: {
      root: {
        base: 'flex md:!flex-row-reverse bg-white dark:border-gray-700 dark:bg-gray-800 w-full max-w-[700px] mx-auto justify-between flex-col',
        children: 'flex md:h-full flex-col justify-center md:p-6',
        horizontal: {
          off: 'flex-col',
          on: 'flex-col md:flex-row',
        },
        href: 'hover:bg-gray-100 dark:hover:bg-gray-700',
      },
      base: 'flex md:!flex-row-reverse bg-white dark:border-gray-700 dark:bg-gray-800 w-full max-w-[700px] mx-auto justify-between flex-col',
      children: 'flex md:h-full flex-col justify-center md:p-6',
      horizontal: {
        off: 'flex-col',
        on: 'flex-col md:flex-row',
      },
      href: 'hover:bg-gray-100 dark:hover:bg-gray-700',
      img: {
        base: '',
        horizontal: {
          off: 'rounded-t-lg',
          on: 'sm:h-96 sm:mb-0 object-contain md:h-auto md:rounded-none md:rounded-l-lg desktop:!w-[340px] 2lg:w-[300px] md:w-[400px] w-full mb-6',
        },
      },
    },
    tab: {
      base: 'flex flex-col lg:flex-col-reverse w-full justify-between',
      tablist: {
        base: 'flex text-center',
        styles: {
          default: 'flex-wrap lg:gap-8 gap-4 justify-center',
          underline:
            'flex-wrap -mb-px border-b border-gray-200 dark:border-gray-700',
          pills:
            'flex-wrap font-medium text-sm text-gray-500 dark:text-gray-400',
          fullWidth:
            'hidden text-sm font-medium rounded-lg divide-x divide-gray-200 shadow sm:flex dark:divide-gray-700 dark:text-gray-400',
        },
        tabitem: {
          base: 'flex items-center justify-center text-sm font-medium first:ml-0 disabled:cursor-not-allowed disabled:text-gray-400 disabled:dark:text-gray-500',
          styles: {
            default: {
              base: '',
              active: {
                on: 'md:text-xl font-serif font-extrabold dark:bg-gray-800 dark:text-blue-500 text-deep-red outline-none',
                off: 'text-mogok-grey md:text-xl font-serif font-extrabold transition-all duration-300 hover:text-deep-red outline-none',
              },
            },
            underline: {
              base: 'rounded-t-lg',
              active: {
                on: 'text-blue-600 rounded-t-lg border-b-2 border-blue-600 active dark:text-blue-500 dark:border-deep-red',
                off: 'border-b-2 border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-600 dark:text-gray-400 dark:hover:text-gray-300',
              },
            },
            pills: {
              base: '',
              active: {
                on: 'rounded-lg bg-blue-600 text-white',
                off: 'rounded-lg hover:text-gray-900 hover:bg-gray-100 dark:hover:bg-gray-800 dark:hover:text-white',
              },
            },
            fullWidth: {
              base: 'ml-2 first:ml-0 w-full first:rounded-l-lg last:rounded-r-lg',
              active: {
                on: 'inline-block p-4 w-full text-gray-900 bg-gray-100 focus:ring-4 focus:ring-blue-300 active focus:outline-none dark:bg-gray-700 dark:text-white',
                off: 'bg-white hover:text-gray-700 hover:bg-gray-50 focus:ring-4 focus:ring-blue-300 focus:outline-none dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700',
              },
            },
          },
          icon: 'mr-2 h-5 w-5',
        },
      },
      tabpanel: 'p-4',
    },
    textInput: {
      addon:
        'inline-flex items-center rounded-l-md border border-r-0 border-deep-red bg-gray-200 px-3 text-sm text-mogok-dark-grey',
      field: {
        base: 'relative w-full',
        input: {
          base: 'block w-full border disabled:cursor-not-allowed disabled:opacity-50',

          colors: {
            gray: 'bg-white border-deep-red text-gray-900 focus:border-deep-red focus:ring-deep-red dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-deep-red dark:focus:ring-blue-500',}
        },
      },
    },
    textarea: {
      base: 'block w-full rounded-lg border disabled:cursor-not-allowed disabled:opacity-50 resize-none text-mogok-dark-grey',
      colors: {
        gray: 'border-deep-red text-black focus:border-deep-red focus:ring-deep-red dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-border-black dark:focus:border-deep-red dark:focus:ring-deep-red',
      },
    },
  },
};

export default theme;
