import { FC } from 'react';
import { useRecoilState } from 'recoil';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline'
import { useTranslation } from 'react-i18next';

import TeamSliderState from '../../recoil/TeamSliderState';

import saitoPic from '../../assets/img/Saito_Profile.jpg';
import ritsukoPic from '../../assets/img/Ritsuko_Profile.jpg';
import markPic from '../../assets/img/Mark_Profile.jpg';
import ludovicPic from '../../assets/img/Ludovic_Profile.jpg';

const TeamSlider: FC = () => {
  const [sliderState, setSliderState] = useRecoilState(TeamSliderState);
  const { t } = useTranslation();

  const navigateTo = (index: number) => {
    setSliderState((ss) => {
      return { ...ss, activeIndex: index };
    });
  };

  const nextSlide = () => {
    switch (sliderState.activeIndex) {
      case 0:
        return navigateTo(1);
      case 1:
        return navigateTo(2);
      case 2:
        return navigateTo(3);
      case 3:
        return navigateTo(0);
    }
  }

  const prevSlide = () => {
    switch (sliderState.activeIndex) {
      case 0:
        return navigateTo(3);
      case 1:
        return navigateTo(0);
      case 2:
        return navigateTo(1);
      case 3:
        return navigateTo(2);
    }
  }

  return (
    <div className='px-4 mb-14 flex md:hidden'>
      <div className='overflow-hidden relative flex'>
        <div className='absolute right-0 top-7 flex gap-2 z-10 bg-gradient-to-l from-white via-white to-transparent'>
          <button
            className='flex items-center justify-center w-8 h-8 rounded-full bg-red-100'
            onClick={prevSlide}
          >
            <ChevronLeftIcon className='text-mogok-red w-4' />
          </button>
          <button
            className='flex items-center justify-center w-8 h-8 rounded-full bg-red-100'
            onClick={nextSlide}
          >
            <ChevronRightIcon className='text-mogok-red w-4' />
          </button>
        </div>
        <div className='absolute left-0 top-14 z-10'>
          <ul className='flex items-center gap-2 mt-6 lg:mt-0'>
            <li
              className={'flex w-2 h-2 rounded-full cursor-pointer transition-all duration-300 hover:bg-mogok-red' + (sliderState.activeIndex === 0 ? ' bg-mogok-red' : ' bg-red-100')}
              onClick={() => navigateTo(0)}
            ></li>
            <li
              className={'flex w-2 h-2 rounded-full cursor-pointer transition-all duration-300 hover:bg-mogok-red' + (sliderState.activeIndex === 1 ? ' bg-mogok-red' : ' bg-red-100')}
              onClick={() => navigateTo(1)}
            ></li>
            <li
              className={'flex w-2 h-2 rounded-full cursor-pointer transition-all duration-300 hover:bg-mogok-red' + (sliderState.activeIndex === 2 ? ' bg-mogok-red' : ' bg-red-100')}
              onClick={() => navigateTo(2)}
            ></li>
            <li
              className={'flex w-2 h-2 rounded-full cursor-pointer transition-all duration-300 hover:bg-mogok-red' + (sliderState.activeIndex === 3 ? ' bg-mogok-red' : ' bg-red-100')}
              onClick={() => navigateTo(3)}
            ></li>
          </ul>
        </div>
        <div className={'flex transition-all duration-300 relative w-full ' + (sliderState.activeIndex === 0 ? 'right-0' : '') + (sliderState.activeIndex === 1 ? 'right-full' : '') + (sliderState.activeIndex === 2 ? 'right-[200%]' : '') + (sliderState.activeIndex === 3 ? 'right-[300%]' : '')}>
          <div className='flex flex-col min-w-full'>
            <span className='text-mogok-red text-sm mb-2'>01</span>
            <h2 className='font-serif text-mogok-red text-xl pb-14 mb-11 border-b border-mogok-red'>Shinichi Saito</h2>
            <div className='flex flex-col gap-12'>
              <div className='flex items-center gap-6'>
                <img className='w-28 h-28 object-cover rounded-lg' src={saitoPic} alt='team member' />
                <h2 className='text-base font-serif text-mogok-red max-w-[176px]'>{t('team', { context: 'shinichi_saito_title' })}</h2>
              </div>
              <div>
                <p className='text-mogok-dark-gray'>
                  {t('team', { context: 'shinichi_saito_desc1' })} {t('team', { context: 'shinichi_saito_desc2' })} {t('team', { context: 'shinichi_saito_desc3' })}               </p>
              </div>
            </div>
          </div>
          <div className='flex flex-col min-w-full'>
            <span className='text-mogok-red text-sm mb-2'>02</span>
            <h2 className='font-serif text-mogok-red text-xl pb-14 mb-11 border-b border-mogok-red'>Yukawa Ritsuko</h2>
            <div className='flex flex-col gap-12'>
              <div className='flex items-center gap-6'>
                <img className='w-28 h-28 object-cover rounded-lg' src={ritsukoPic} alt='team member' />
                <h2 className='text-base font-serif text-mogok-red max-w-[176px]'>{t('team', { context: 'yukawa_ritsuko_title' })}</h2>
              </div>
              <div>
                <p className='text-mogok-dark-gray'>
                  {t('team', { context: 'yukawa_ritsuko_desc1' })} {t('team', { context: 'yukawa_ritsuko_desc2' })} {t('team', { context: 'yukawa_ritsuko_desc3' })}
                </p>
              </div>
            </div>
          </div>
          <div className='flex flex-col min-w-full'>
            <span className='text-mogok-red text-sm mb-2'>03</span>
            <h2 className='font-serif text-mogok-red text-xl pb-14 mb-11 border-b border-mogok-red'>Mark Karpelès</h2>
            <div className='flex flex-col gap-12'>
              <div className='flex items-center gap-6'>
                <img className='w-28 h-28 object-cover rounded-lg' src={markPic} alt='team member' />
                <h2 className='text-base font-serif text-mogok-red max-w-[176px]'>{t('team', { context: 'mark_karpelès_title' })}</h2>
              </div>
              <div>
                <p className='text-mogok-dark-gray'>
                  {t('team', { context: 'mark_karpelès_desc1' })} {t('team', { context: 'mark_karpelès_desc2' })} {t('team', { context: 'mark_karpelès_desc3' })}
                </p>
              </div>
            </div>
          </div>
          <div className='flex flex-col min-w-full'>
            <span className='text-mogok-red text-sm mb-2'>04</span>
            <h2 className='font-serif text-mogok-red text-xl pb-14 mb-11 border-b border-mogok-red'>Ludovic Barreca</h2>
            <div className='flex flex-col gap-12'>
              <div className='flex items-center gap-6'>
                <img className='w-28 h-28 object-cover rounded-lg' src={ludovicPic} alt='team member' />
                <h2 className='text-base font-serif text-mogok-red max-w-[176px]'>{t('team', { context: 'ludovic_barreca_title' })}</h2>
              </div>
              <div>
                <p className='text-mogok-dark-gray'>
                  {t('team', { context: 'ludovic_barreca_desc1' })} {t('team', { context: 'ludovic_barreca_desc2' })} {t('team', { context: 'ludovic_barreca_desc3' })}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TeamSlider;
