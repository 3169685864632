import { atom } from 'recoil';

const HowToBuySliderState = atom({
  key: 'HowToBuySliderState',
  default: {
    activeIndex: 0
  }
});

export default HowToBuySliderState;
