import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { CustomFlowbiteTheme, Flowbite, Tabs } from 'flowbite-react';
import TeamMember from '../TeamMember/TeamMember';

import saitoPic from '../../assets/img/Saito_Profile.jpg';
import ritsukoPic from '../../assets/img/Ritsuko_Profile.jpg';
import markPic from '../../assets/img/Mark_Profile.jpg';
import ludovicPic from '../../assets/img/Ludovic_Profile.jpg';

import EclipsCirclesRed from '../../assets/img/eclipse-circles-red.svg';

interface TeamPageTabsProps { }

const theme: CustomFlowbiteTheme = {
  tab: {
    base: 'flex flex-col lg:flex-row gap-5 lg:gap-2 justify-center items-center h-full',
    tablist: {
      base: 'flex lg:flex-col justify-start lg:w-1/2',
      styles: {
        fullWidth:
          'text-sm font-medium text-start gap-y-5 text-mogok-red',
      },
      tabitem: {
        base: 'flex items-center p-4 text-xl font-landing-title font-extrabold first:ml-0 disabled:cursor-not-allowed disabled:text-gray-400 disabled:dark:text-gray-500',
        styles: {
          fullWidth: {
            base: 'ml-2 first:ml-0 w-full last:rounded-r-lg',
            active: {
              on: 'inline-block p-4 w-full text-light-red border-b-2 border-mogok-red transform transition ease-in-out duration-400 translate-x-[-8px] lg:relative after:absolute after:right-0 after:bottom-0 after:w-2 after:h-2 after:bg-mogok-red after:rounded-full after:-mb-1 focus:ring-0 active',
              off: 'text-red-200 border-b border-red-200 w-3/4 lg:relative after:absolute after:right-0 after:bottom-0 after:w-2 after:h-2 after:bg-red-200 after:rounded-full after:-mb-1 hover:border-red-400 hover:text-red-400 focus:ring-0',
            },
          },
        },
      },
    },
  },
}

const TeamPageTabs: FC<TeamPageTabsProps> = () => {
  const { t } = useTranslation();

  useEffect(() => {
    const handleResize = () => {
      setWindowSize(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <section className='py-12 md:py-24 overflow-hidden'>
      <div className="flex flex-col">
        <div className="relative mogok-container mx-4 lg:mx-auto lg:px-0">
          <div className='w-2 h-2 md:w-3 md:h-3 rounded-full bg-mogok-red absolute top-0 md:-top-6 left-0 md:-left-4'></div>
          <span className="font-serif text-4xl md:text-[80px] text-start z-10 text-mogok-red pl-3 md:pl-0 flex">
            {t('meet_the')}
            <span className='ml-2 md:ml-4 relative'>
              <img className='absolute left-0 -top-1 md:-top-6 w-[300px]' src={EclipsCirclesRed} alt='red circles' />
              {t('team')}
            </span>
          </span>
        </div>
        <div className='mogok-container mt-20 px-4 xl:px-0 hidden md:block'>
          <Flowbite theme={{ theme }}>
            <Tabs.Group
              aria-label="Full width tabs"
              // @ts-ignore
              // eslint-disable-next-line
              style="fullWidth"
            >
              <Tabs.Item title={<span className='text-left text-base font-sans font-light'><h1 className='text-3xl lg:text-[40px] font-serif font-extrabold mt-5 md:leading-10'>{t('team', { context: 'ludovic_barreca_name' })}</h1></span>}>
                <TeamMember
                  image={ludovicPic}
                  role={t('team', { context: 'ludovic_barreca_title' })} />
                <p className="max-w-xl text-sm mt-8 text-gray-500">
                  {t('team', { context: 'ludovic_barreca_desc1' })}
                  <br /><br />
                  {t('team', { context: 'ludovic_barreca_desc2' })}
                  <br /><br />
                  {t('team', { context: 'ludovic_barreca_desc3' })}
                </p>
              </Tabs.Item>
              <Tabs.Item title={<span className='text-left text-base font-sans font-light'><h1 className='text-3xl lg:text-[40px] font-serif font-extrabold mt-5 md:leading-10'>{t('team', { context: 'mark_karpelès_name' })}</h1></span>}>
                <TeamMember
                  image={markPic}
                  role={t('team', { context: 'mark_karpelès_title' })} />
                <p className="max-w-xl text-sm mt-8 text-gray-500 h-[200px]">
                  {t('team', { context: 'mark_karpelès_desc1' })}
                  <br /><br />
                  {t('team', { context: 'mark_karpelès_desc2' })}
                  <br /><br />
                  {t('team', { context: 'mark_karpelès_desc3' })}
                  <br /><br />
                  {t('team', { context: 'mark_karpelès_desc4' })}

                </p>
              </Tabs.Item>
              <Tabs.Item title={<span className='text-left text-base font-sans font-light'><h1 className='text-3xl lg:text-[40px] font-serif font-extrabold mt-5 md:leading-10'>{t('team', { context: 'shinichi_saito_name' })}</h1></span>}>
                <TeamMember
                  image={saitoPic}
                  role={t('team', { context: 'shinichi_saito_title' })} />
                <p className="max-w-xl text-sm mt-8 text-gray-500">
                  {t('team', { context: 'shinichi_saito_desc1' })}
                  <br /><br />
                  {t('team', { context: 'shinichi_saito_desc2' })}
                  <br /><br />
                  {t('team', { context: 'shinichi_saito_desc3' })}
                </p>

              </Tabs.Item>
              <Tabs.Item title={<span className='text-left text-base font-sans font-light'><h1 className='text-3xl lg:text-[40px] font-serif font-extrabold mt-5 md:leading-10'>{t('team', { context: 'yukawa_ritsuko_name' })}</h1></span>}>
                <TeamMember
                  image={ritsukoPic}
                  role={t('team', { context: 'yukawa_ritsuko_title' })} />
                <p className="max-w-xl text-sm mt-8 text-gray-500">
                  {t('team', { context: 'yukawa_ritsuko_desc1' })}
                  <br /><br />
                  {t('team', { context: 'yukawa_ritsuko_desc2' })}
                  <br /><br />
                  {t('team', { context: 'yukawa_ritsuko_desc3' })}
                </p>
              </Tabs.Item>
            </Tabs.Group>
          </Flowbite>
        </div>
      </div>
    </section>
  );
};

export default TeamPageTabs;
