import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { getLocale } from '@karpeleslab/klbfw';

import en from './languages/en/global.en.json';
import ja from './languages/ja/global.ja.json';
import enCommon from './languages/en/common.en.json';
import jaCommon from './languages/ja/common.ja.json';

const enContent = {
    translation: {
        ...en
    },
    common: {
        ...enCommon
    }
}


const jaContent = {
    translation: {
        ...ja
    },
    common: {
        ...jaCommon
    }
}

i18next.use(initReactI18next).use(LanguageDetector).init({
    lng: getLocale(),
    debug: true,
    resources: {
        'en-US': enContent,
        'ja-JP': jaContent
    }
})
