import { FC } from 'react';
import { Dropdown as FlowbiteDropdown, DropdownProps as FlowbiteDropdownProps, CustomFlowbiteTheme, Flowbite } from 'flowbite-react';
// @ts-ignore
import { getUrl } from '@karpeleslab/klbfw';
import { LanguageIcon } from '@heroicons/react/24/solid';

interface DropdownProps extends FlowbiteDropdownProps {
  languages: Array<{ lang: string, label: string }>
}

const Dropdown: FC<DropdownProps> = ({ languages, ...props }) => {
  const baseUrl = getUrl().scheme + '://' + getUrl().host;

  const theme: CustomFlowbiteTheme = {
    button: {
      base: `text-black focus:ring-0 focus:ring-transparent  !rounded-none }`,
      color: {
        transparent: "bg-transparent text-white font-serif w-full border-0 font-bold hover:border-b hover:border-b-white "
      },
      inner: {
        base: 'flex items-stretch transition-all duration-200 !px-0 !py-0',
      },
    },
    dropdown: {
      arrowIcon: 'ml-1 h-4 w-4 block md:hidden',
      content: `py-1 focus:outline-none left-0  bg-mogok-red text-white !top-20 !border-0 }`,
      floating: {
        base: `z-10 w-fit rounded divide-y divide-gray-100 shadow focus:outline-none !border-0 !border-t !border-t-white !top-[30px] `,
        item: {
          base: "hover:bg-transparent"
        }
      },
    },
  }

  return (
    <Flowbite theme={{ theme: theme }}>
      <FlowbiteDropdown {...props}
        color="transparent"
        label=<LanguageIcon className="h-8 w88" />>
        <FlowbiteDropdown.Item className="flex flex-col items-center gap-1 font-serif font-bold w-full">
          {
            languages.map((obj, index) => (
              <span key={index}
                className='hover:bg-mogok-deep-red px-3 py-2 w-full text-center'
                onClick={() => { window.location.href = baseUrl + '/l/' + obj.value + '/' } }>

                {obj.display}
              </span>
            ))
          }
        </FlowbiteDropdown.Item>
      </FlowbiteDropdown>
    </Flowbite>
  );
}

export default Dropdown
