import { FC } from 'react';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';

const textLoop = {
  animate: {
    x: [0, -2000],
    transition: {
      x: {
        repeat: Infinity,
        repeatType: "loop",
        duration: 15,
        ease: "linear",
      },
    },
  },
};

const TextLoop: FC = () => {
  const { t } = useTranslation();

  return (
    <div className='absolute top-32 md:top-44 w-screen max-w-full h-12 overflow-hidden'>
      <motion.div
        className='relative whitespace-nowrap mix-blend-difference'
        variants={textLoop}
        animate="animate"
      >
        <h2 className='flex'>
          <span className='font-serif text-2xl md:text-5xl text-mogok-gray flex items-center'>{t('home', { context: 'text_loop' })}<span className='w-2 h-2 md:w-3 md:h-3 bg-mogok-gray rounded-full mx-6'></span></span>
          <span className='font-serif text-2xl md:text-5xl text-mogok-gray flex items-center'>{t('home', { context: 'text_loop' })}<span className='w-2 h-2 md:w-3 md:h-3 bg-mogok-gray rounded-full mx-6'></span></span>
          <span className='font-serif text-2xl md:text-5xl text-mogok-gray flex items-center'>{t('home', { context: 'text_loop' })}<span className='w-2 h-2 md:w-3 md:h-3 bg-mogok-gray rounded-full mx-6'></span></span>
          <span className='font-serif text-2xl md:text-5xl text-mogok-gray flex items-center'>{t('home', { context: 'text_loop' })}<span className='w-2 h-2 md:w-3 md:h-3 rounded-full mx-6'></span></span>
        </h2>
      </motion.div>
    </div>
  );
};

export default TextLoop;
