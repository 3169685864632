import React, { FC } from 'react';
import { CustomFlowbiteTheme, Flowbite, Tabs } from 'flowbite-react';
import { useTranslation } from 'react-i18next';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline'
import { useRecoilState } from 'recoil';

import MogokRubyTabsState from '../../recoil/MogokRubyTabs/atom';

import EclipsCirclesRed from '../../assets/img/eclipse-circles-red.svg';
import rubyImage from '../../assets/img/rubyImage.jpg';

interface MogokRubyProps { }

const theme: CustomFlowbiteTheme = {
  tab: {
    base: "flex flex-col gap-2",
    tablist: {
      base: "flex text-center mb-9 md:mb-20 bg-white",
      styles: {
        default: "tabs-controls lg:gap-28 md:gap-4 gap-0 lg:justify-center w-full mt-8 md:mt-12 border-b border-gray-200 pr-10 sm:pr-0 relative transition-all duration-300 overflow-x-scroll sm:overflow-hidden",
      },
      tabitem: {
        base: "flex items-center justify-center p-6 md:p-4 md:pb-10 transition-all duration-300 text-sm md:text-xl text-mogok-red font-serif font-extrabold first:ml-0",
        styles: {
          default: {
            base: '',
            active: {
              on: 'text-mogok-red active-tab',
              off: 'text-red-200 hover:text-mogok-red',
            },
          },
        },
      },
    },
  },
};

const MogokRuby: FC<MogokRubyProps> = () => {
  const { t } = useTranslation();
  const [tabsState, setTabsState] = useRecoilState(MogokRubyTabsState);

  const scrollRight = () => {
    const tabs = document.querySelector('.tabs-controls');
    if (tabs) {
      const { scrollLeft, offsetWidth } = tabs;
      if (tabs.scrollLeft <= offsetWidth) {
        if (tabs.scrollLeft >= 120) {
          setTabsState((ts) => {
            return { ...ts, arrowsState: 'right' }
          })
        }
        tabs.scroll({
          left: scrollLeft + 60,
          behavior: 'smooth',
        });
      }
    }
  }

  const scrollLeft = () => {
    const tabs = document.querySelector('.tabs-controls');
    if (tabs) {
      const { scrollLeft, offsetWidth } = tabs;
      if (tabs.scrollLeft <= offsetWidth) {
        if (tabs.scrollLeft === 0) {
          setTabsState((ts) => {
            return { ...ts, arrowsState: 'left' }
          })
        }
        tabs.scroll({
          left: scrollLeft - 60,
          behavior: 'smooth',
        });
      }
    }
  }

  return (
    <section className='py-12 md:py-24 overflow-hidden'>
      <div className="flex flex-col">
        <div className="relative mogok-container mx-4 lg:mx-auto">
          <div className='w-2 h-2 md:w-3 md:h-3 rounded-full bg-mogok-red absolute top-0 md:-top-6 left-0 md:-left-4'></div>
          <span className="font-serif text-4xl md:text-[80px] text-start z-10 text-mogok-red pl-3 md:pl-0 flex">
            {t('meet_the')}
            <span className='ml-4 relative'>
              <img className='absolute left-0 -top-1 md:-top-6 w-[300px]' src={EclipsCirclesRed} alt='red circles' />
              {t('ruby')}
            </span>
          </span>
        </div>
        <div className='relative overflow-x-hidden'>
          <div className={'absolute right-4 top-12 z-20 flex sm:hidden items-center justify-center w-8 h-8 rounded-full bg-red-100' + (tabsState.arrowsState === 'right' ? ' opacity-0 pointer-events-none' : ' opacity-100')} onClick={scrollRight}>
            <ChevronRightIcon className='text-mogok-red w-4' />
          </div>
          <Flowbite theme={{ theme }}>
            <Tabs.Group
              aria-label="Tabs with underline"
              // @ts-ignore
              // eslint-disable-next-line
              style="default"
              className='mogok-tabs'
            >
              <Tabs.Item active={true} title={t('mogok_ruby')}>
                <div className='mogok-container flex flex-col lg:flex-row items-center justify-between gap-12 mb-12 lg:mb-16'>
                  <img className='w-full lg:max-w-[417px] rounded-lg' src={rubyImage} alt='Tab' />
                  <p className="text-sm text-mogok-gray lg:max-w-[562px]">
                    {t('mogok_ruby', { context: 'desc1' })}
                    <br /><br />
                    {t('mogok_ruby', { context: 'desc2' })}
                    <br /><br />
                    {t('mogok_ruby', { context: 'desc3' })}
                    <br /><br />
                    {t('mogok_ruby', { context: 'desc4' })}
                  </p>
                </div>
              </Tabs.Item>
              <Tabs.Item title={t('experts_interviews')}>
                <div className='mogok-container flex flex-col lg:flex-row items-center justify-between gap-12 mb-12 lg:mb-16'>
                  <img className='w-full lg:max-w-[417px] rounded-lg' src={rubyImage} alt='Tab' />
                  <p className="text-sm text-mogok-gray lg:max-w-[562px]">
                    {t('experts_interviews', { context: 'desc1' })}
                    <br /><br />
                    {t('experts_interviews', { context: 'desc2' })}
                    <br /><br />
                    {t('experts_interviews', { context: 'desc3' })}
                    <br /><br />
                    {t('experts_interviews', { context: 'desc4' })}
                  </p>
                </div>
              </Tabs.Item>
              <Tabs.Item title={t('scientific_analysis')}>
                <div className='mogok-container flex flex-col lg:flex-row items-center justify-between gap-12 mb-12 lg:mb-16'>
                  <img className='w-full lg:max-w-[417px] rounded-lg' src={rubyImage} alt='Tab' />
                  <p className="text-sm text-mogok-gray lg:max-w-[562px]">
                    {t('scientific_analysis', { context: 'desc1' })}
                    <br /><br />
                    {t('scientific_analysis', { context: 'desc2' })}
                    <br /><br />
                    {t('scientific_analysis', { context: 'desc3' })}
                    <br /><br />
                    {t('scientific_analysis', { context: 'desc4' })}
                    <br /><br />
                    {t('scientific_analysis', { context: 'desc5' })}
                    <br /><br />
                    {t('scientific_analysis', { context: 'desc6' })}
                  </p>
                </div>
              </Tabs.Item>
            </Tabs.Group>
          </Flowbite>
          <div className={'absolute left-4 top-12 z-20 flex sm:hidden items-center justify-center w-8 h-8 rounded-full bg-red-100' + (tabsState.arrowsState === 'left' ? ' opacity-0 pointer-events-none' : ' opacity-100')} onClick={scrollLeft}>
            <ChevronLeftIcon className='text-mogok-red w-4' />
          </div>
        </div>
      </div>
    </section>
  );
};

export default MogokRuby;
