import React, { FC } from 'react';

interface TeamMemberProps {
  role: string;
  image: string;
  children?: JSX.Element;
}

const TeamMember: FC<TeamMemberProps> = ({ role, image, children }) => {
  return (
    <div className="flex gap-5 lg:gap-12 justify-around items-center w-full max-w-7xl mx-auto">
      <div>
        <img className="w-52 h-52 rounded-lg object-cover object-left-top" src={image} alt="Project Leader" />
      </div>
      <div>
        <div className="inner-content flex flex-col lg:items-start">
          <h3 className="font-serif font-extrabold w-52 text-mogok-red sm:text-xl text-lg lg:mb-5 mb-4">
            {role}
          </h3>
        </div>
        <div className="bio grid lg:gap-7 gap-3 lg:max-h-[448px] max-w-xs">
          {children}
        </div>
      </div>
    </div>
  );
};

export default TeamMember;
