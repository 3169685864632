import { atom } from 'recoil';

const NavbarState = atom({
  key: 'NavbarState',
  default: {
    open: false,
    activeBarPos: 0,
  }
});

export default NavbarState;
