import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '../../components/common/Button/Button';

import WhitepaperBook from '../../assets/img/whitepaper-book.png';
import EclipsCircles from '../../assets/img/eclipse-circles.svg';

interface WhitepaperProps { }

const Whitepaper: FC<WhitepaperProps> = () => {
  const { t, i18n } = useTranslation();

  return (
    <section className='bg-mogok-red pt-10 pb-5 md:pt-32 md:pb-52 flex items-center overflow-hidden'>
      <div className='mogok-container flex justify-between flex-col gap-5 px-4 xl:px-0 lg:flex-row md:gap-0'>
        <div className='max-w-[540px] w-full'>
          <h1 className='font-serif text-5xl md:text-7xl text-white flex flex-col'>

            {
              i18n.language === 'ja-JP' ? <>
                <span className='relative'>
                  <img className='absolute -top-3 md:-top-12 -left-9 md:left-2 w-[219px] md:w-[348px]' src={EclipsCircles} alt='white circles' />
                  <span className='contents top-2 md:top-1/2 left-0 md:-translate-y-1/2'>{t('whitepaper', { context: 'title1' })}</span>
                </span>
              </> : <>{t('whitepaper', { context: 'title1' })}</>
            }
            {
              i18n.language === 'ja-JP' && <><span className='drop-shadow-border text-mogok-red'>{t('whitepaper', { context: 'title2' })}</span> {t('whitepaper', { context: 'title3' })}</>
            }

            {i18n.language !== "ja-JP" &&
              <>
                <span className='drop-shadow-border text-mogok-red'>{t('whitepaper', { context: 'title2' })}</span>
                {t('whitepaper', { context: 'title3' })}
                <span>{t('whitepaper', { context: 'title4' })}</span>
                <span>{t('whitepaper', { context: 'title5' })}</span>
                <span className='relative'>
                  <img className='relative -top-3 md:top-0 -left-9 md:-left-14 w-[219px] md:w-[348px]' src={EclipsCircles} alt='white circles' />
                  <span className='absolute top-2 md:top-1/2 left-0 md:-translate-y-1/2'>{t('whitepaper', { context: 'title6' })}</span>
                </span>

              </>}
          </h1>
        </div>
        <div className='flex flex-col lg:justify-between xl:items-end relative pb-5 lg:pb-8'>
          <div className='bg-mogok-deep-red absolute w-screen h-screen lg:rounded-lg top-20 lg:top-0 -left-5 lg:left-20 xl:left-60'></div>
          <img className='relative mx-auto lg:mx-0 xl:left-36 -top-10 w-[680px]' src={WhitepaperBook} alt='Whitepaper Book' />
          <a className='max-w-[426px] relative mx-auto lg:mx-0' href="/Mogok_Whitepaper_v1.0.pdf" target='_blank'>
            <Button label={t('whitepaper', { context: 'read_the_whitepaper' })} color='white' size='xl' />
          </a>
        </div>
      </div>
    </section>
  );
};

export default Whitepaper;
