import React from 'react';
import Dropdown from '../DropDown/DropDown';

const LangSwitcher = ({ ...props }) => {
  return (
    <Dropdown languages={[{ display: "English", shortcut: "EN", value: "en-US" }, { display: "日本語", shortcut: "JP", value: "ja-JP" }]} />
  );
}

export default LangSwitcher
