import React, { FC } from 'react'

import PioneerSection from '../../components/PioneerSection/PioneerSection'
import FAQSection from '../../components/FAQSection/FAQSection'
import MogokPionnerProgram from '../../components/MogokPionnerProgram/MogokPionnerProgram';

import bgParticule from "../../assets/img/bgParticule.png";

const PioneerProgram: FC = () => {
  return (
    <div className='bg-mogok-red'>

      <div className='' style={{
        backgroundImage: `url(${bgParticule})`,
        backgroundSize: '100%',
        backgroundRepeat: 'repeat-y',
      }}>
        <MogokPionnerProgram />
        <PioneerSection />
        <FAQSection />
      </div>
    </div>
  )
}

export default PioneerProgram
