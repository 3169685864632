import React, { FC, useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import { rest } from '@karpeleslab/klbfw';
import { Modal, Button, Flowbite, CustomFlowbiteTheme } from 'flowbite-react';
import { XMarkIcon, ArrowLeftIcon } from '@heroicons/react/24/outline';

import NewsSinglePost from '../NewsSinglePost/NewsSinglePost';
import NewsPost from '../NewsPost/NewsPost';

import NewsState from '../../recoil/NewsState';
import NewsDataState from '../../recoil/NewsData';
import NewsSinglePostState from '../../recoil/NewsSinglePost';

interface NewsModalProps { }

const theme: CustomFlowbiteTheme = {
  button: {
    base: 'group flex h-min items-center justify-center focus:ring-transparent',
    size: {
      icon: '',
    },
  },
  modal: {
    root: {
      base: 'fixed top-0 right-0 left-0 top-[200vh] md:top-0 z-50 overflow-y-auto overflow-x-hidden md:inset-0 h-full',
      show: {
        on: 'flex bg-gray-900 bg-opacity-50 !top-0',
      },
    },
    content: {
      base: 'relative h-full m-auto w-full sm:p-4 md:h-auto sm:w-auto overflow-hidden',
      inner:
        'absolute sm:relative rounded-lg bg-white p-4 sm:p-9 modalAnimation transition-all duration-300',
    },
    sizes: {
      '5xl': 'max-w-[900px]',
    },
    body: {
      base: '',
    },
  },
};

const NewsModal: FC<NewsModalProps> = () => {
  const [newsState, setNewsState] = useRecoilState(NewsState);
  const [newsData, setNewsData] = useRecoilState(NewsDataState);
  const [newsSingleState, setNewsSingleState] = useRecoilState(NewsSinglePostState);
  const { t } = useTranslation();

  const closeNewsModal = () => {
    setNewsState((ns) => {
      return {
        ...ns,
        opened: false,
        type: 'list',
      };
    });
  };

  const backBtn = () => {
    setNewsState((ns) => {
      return {
        ...ns,
        type: 'list',
      };
    });
    setNewsSingleState((ns) => {
      return {
        ...ns,
        title: '',
        selectedNews: null,
      };
    });
  };

  useEffect(() => {
    if (newsState.type === 'list') {
      rest('Content/Cms/@news:search', 'GET', {
        page_no: 1,
        results_per_page: 10,
      })
        .then((result) => {
          setNewsData((nd) => {
            return { ...nd, news: result.data.data, newsLoaded: true };
          });
        })
        .catch((error) => { });
    }
  }, [newsState.type, setNewsData]);

  useEffect(() => {
    if (newsState.type === 'post') {
      rest('Content/Cms/@news:loadSlug', 'GET', {
        slug: newsState.selectedSlug,
      })
        .then((result) => {
          setNewsSingleState((nms) => {
            return {
              ...nms,
              selectedNews: result.data.content_cms_entry_data,
            };
          });
        })
        .catch((error) => { });
    }
  }, [newsState.type, newsState.selectedSlug, setNewsSingleState]);
  // @TODO prevents re-render when just updating the state for loading. To refactor with loading.
  if (newsSingleState.selectedNews === null && newsData.news.length === 0)
    return;

  return (
    <Flowbite theme={{ theme }}>
      <Modal
        dismissible={false}
        show={newsState.opened}
        size="5xl"
        className={newsState.opened ? 'modal-active' : ''}
      >
        <div>
          <div className="flex justify-between pb-9">
            <div>
              <button
                className="font-serif text-xl text-mogok-gray flex items-center gap-4 mb-4"
                onClick={backBtn}
              >
                <ArrowLeftIcon className="text-mogok-gray w-5 h-5" />
                {t('all_news', { ns: 'common' })}
              </button>
              <h2 className="font-serif text-4xl text-mogok-red">
                {t('latest_news', { ns: 'common' })}
              </h2>
            </div>
            <Button color="transparent" size="icon" onClick={closeNewsModal}>
              <XMarkIcon className="w-5 h-5 text-gray-800" />
            </Button>
          </div>
          <div className="flex items-center w-full">
            <div className="w-full h-[1px] bg-mogok-red"></div>
            <div className="w-1 h-1 bg-mogok-red rounded-full"></div>
          </div>
        </div>
        <Modal.Body>
          <div
            className={
              'max-h-[507px] pt-6' +
              (newsData.news.length === 0 ? '' : ' overflow-y-scroll')
            }
          >
            {newsState.type === 'list' ? (
              newsData.news.map((article, index) => {
                return (
                  <div key={index}>
                    <NewsPost list={true} data={article} />
                  </div>
                );
              })
            ) : newsData.news.length > 0 ? (
              <NewsSinglePost />
            ) : (
              <></>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </Flowbite>
  );
};

export default NewsModal;
