import { Card, CustomFlowbiteTheme, Flowbite } from 'flowbite-react';
import React, { FC } from 'react'

interface PionnerCardProps {
  index: number;
  icon: string;
  title: string;
  content: string;
}

const PionnerCardTheme: CustomFlowbiteTheme = {
  card: {
    "root": {
      "base": "flex rounded-lg bg-mogok-deep-red sm:max-w-[360px] lg:max-w-full w-full",
      "children": "flex h-full flex-col justify-center gap-9 p-9",
    }
  }
}

const PionnerCard: FC<PionnerCardProps> = ({ index, icon, title, content }) => {
  return (
    <Flowbite theme={{ theme: PionnerCardTheme }}>
      <Card>
        <div className='flex items-center gap-9'>
          <img className='w-16' src={icon} alt="Card Icon" />
          <div className='flex flex-col gap-4'>
            <span className='text-white text-sm'>{index}</span>
            <h2 className='font-serif font-bold text-2xl text-white'>{title}</h2>
          </div>
        </div>
        <p className='text-white text-sm max-w-[288px]'>{content}</p>
      </Card>
    </Flowbite>
  )
}

export default PionnerCard
