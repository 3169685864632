import React, { FC } from 'react';
import { useLinkClickHandler, useLocation } from 'react-router-dom';
import { Navbar, Footer } from 'flowbite-react';

interface NavigationLinkProps {
  to: string;
  children: JSX.Element | string;
  type: string;
}

const NavigationLink: FC<NavigationLinkProps> = ({ to, children, type }) => {
  const location = useLocation();
  const clickHandler = useLinkClickHandler(to);

  return (
    <>
      {type === 'navbar' ?
        <Navbar.Link
          href={to}
          active={location.pathname === to}
          onClick={clickHandler}
        >
          {children}
        </Navbar.Link> :
        <Footer.Link
          href={to}
          onClick={clickHandler}
        >
          {children}
        </Footer.Link> 
      }
    </>
  );
};

export default NavigationLink;
