import React, { FC } from 'react';
import { Button as MogokButton, Flowbite, CustomFlowbiteTheme, Spinner } from 'flowbite-react';
import { motion } from 'framer-motion';

import { ArrowLongRightIcon } from '@heroicons/react/24/outline';

interface ButtonProps {
  label: string;
  color: string;
  size: string;
  icon?: string;
  loading?: boolean
}

const theme: CustomFlowbiteTheme = {
  button: {
    base: 'group flex h-min items-center justify-center p-0.5 text-center font-medium focus:z-10 focus:ring-transparent',
    color: {
      white: 'text-white bg-transparent w-fit',
      red: 'text-mogok-red bg-transparent',
      transparent: "text-white bg-transparent"
    },
    inner: {
      base: 'flex items-center text-left font-serif md:gap-6',
    },
    outline: {
      pill: {
        off: '',
      },
    },
    size: {
      xs: 'text-xs px-2 py-1',
      sm: 'text-sm px-3 py-1.5',
      md: 'text-sm px-4 py-2',
      lg: 'font-serif text-xl md:text-2xl md:gap-7',
      xl: 'text-2xl md:text-4xl',
    },
  },
}

const Button: FC<ButtonProps> = ({ label, color, size, icon, loading }) => {
  return (
    <Flowbite theme={{ theme }}>
      <div className={`flex justify-between items-center ${loading && "pointer-events-none"}`}>
        {icon === '' || icon === undefined ? <></> : <img className='w-10 h-10' src={icon} alt='Button icon' />}
        <motion.div
          whileHover={{
            x: 10
          }}
          transition={{ bounce: 1 }}
        >
          <MogokButton color={color} size={size}>
            {label}
            {loading ?
              <Spinner color="failure" />
              :
              <div className={'flex items-center justify-center rounded-full transition-all duration-300 ' + (size === 'lg' ? 'px-6 h-12' : 'px-10 h-20') + (color === 'white' ? ' group-hover:bg-white' : ' group-hover:bg-mogok-red')} >
                <ArrowLongRightIcon className={'transition-all duration-300 ' + (color === 'white' ? 'group-hover:text-mogok-red' : 'group-hover:text-white') + (size === 'lg' ? ' w-11' : ' w-12')} />
              </div>
            }
          </MogokButton>
        </motion.div>
      </div>
    </Flowbite>
  );
};

export default Button;
