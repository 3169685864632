import React from 'react'
import { ArrowLongRightIcon } from '@heroicons/react/24/outline';

import PionnerCaroucel from '../PionnerCaroucel/PionnerCaroucel'

const PioneerSection = () => {
  return (
    <section className='min-h-screen pb-12 pt-9 md:pb-24 md:py-24'>
      <div className='mogok-container px-4'>
        <div className='mb-16 md:mb-24'>
          <div className='relative'>
            <div className='absolute bg-white w-3 h-3 rounded-full -left-3 -top-4'></div>
            <h2 className='font-serif text-4xl md:text-6xl font-bold text-white mb-6 md:mb-12'>「パイオニアプログラム」の特典</h2>
          </div>
          <p className='text-white max-w-[500px] text-sm md:text-base'>モゴックのパイオニアメンバーになることで、二度と手に入らない独占的な特典を得ることができます。これらの特典は限定数で提供され、先着順で予約ができます。。あなたの参加レベルに応じて、さまざまな特典が用意されています。</p>
        </div>
        <PionnerCaroucel />
        <div className='flex justify-center md:justify-end md:ml-auto md:mt-28 mt-9'>
          <a href="https://app.uniswap.org/#/tokens/polygon/0xc37cca16ff2db4c82021d3a5bead3796071ecfcb" rel="noreferrer" target="_blank" className='group flex items-center gap-3 mb-12 py-2 px-4 hover:bg-white  bg-mogok-deep-red  ease-in duration-150 rounded-full font-serif text-2xl text-white hover:text-mogok-red'>
            <span className='hidden md:block'>今すぐ購入</span>
            <span className='md:hidden block'>今すぐ購入</span>
            <ArrowLongRightIcon className='w-8 h-8 text-white group-hover:text-mogok-red' />
          </a>
        </div>
      </div>
    </section>
  )
}

export default PioneerSection
