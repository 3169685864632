import React, { FC, useEffect } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { rest } from '@karpeleslab/klbfw';
import { motion } from 'framer-motion';
import {
  Button as MogokButton,
  CustomFlowbiteTheme,
  Flowbite,
} from 'flowbite-react';
import { Link } from 'react-router-dom';

import NewsModal from '../../components/NewsModal/NewsModal';
import Button from '../../components/common/Button/Button';
import TextLoop from '../../components/TextLoop/TextLoop';
import NewsPost from '../../components/NewsPost/NewsPost';
import HeroSection from '../../components/HeroSection/HeroSection';
import { useTranslation } from 'react-i18next';
import MogokToast from '../../components/common/MogokToast/MogokToast';

import NewsState from '../../recoil/NewsState';
import NewsDataState from '../../recoil/NewsData';

import redCircles from '../../assets/img/red-circles.png';
import realRuby from '../../assets/img/real-ruby.png';
import giaLogo from '../../assets/img/GIA.png';
import bgImage from '../../assets/img/bg-image.png';
import bookIcon from '../../assets/img/bookIcon.svg'

interface HomeProps { }

const theme: CustomFlowbiteTheme = {
  button: {
    base: 'group flex focus:ring-transparent',
    size: {
      xl: 'font-serif text-xl uppercase text-white',
    },
  },
};

const Home: FC<HomeProps> = () => {
  const { t, i18n } = useTranslation();
  const setNewsState = useSetRecoilState(NewsState);
  const [newsData, setNewsData] = useRecoilState(NewsDataState);
  const openNewsModal = () => {
    setNewsState((nms) => {
      return { ...nms, type: 'list', opened: true, title: t('news', { context: 'title' }) };
    });
  };

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  }

  useEffect(() => {
    rest('Content/Cms/@news:search', 'GET', {
      page_no: 1,
      results_per_page: 10,
    })
      .then((result) => {
        setNewsData((hs) => {
          return { ...hs, news: result.data.data };
        });
      })
      .catch((error) => { });
  }, [setNewsData]);

  return (
    <Flowbite theme={{ theme }}>
      <div className="bg-mogok-red">
        <MogokToast title='New documentation available!'
          subtitle='It covers everything to start'
          icon={<img src={bookIcon} alt='book icon' />}
        />
        <HeroSection isAnimating={false} />
        <section className="relative">
          <img
            className="absolute w-full h-full top-0 left-0"
            src={bgImage}
            alt="video thumbnail"
          />
          <div className="mogok-container px-4 lg:px-0 flex items-center flex-col gap-16 lg:gap-0 lg:flex-row w-full py-24 lg:py-0 lg:h-[660px] relative z-20">
            <div className="text-center lg:text-left lg:w-1/2">
              <h2 className="font-serif text-4xl lg:text-6xl text-white relative">
                <span className="hidden lg:flex w-3 h-3 rounded-full bg-white absolute -top-4 -left-4"></span>
                {t('home', { context: 'want_to_know_everything' })}
              </h2>
            </div>
            <div className="flex lg:flex-col items-center">
              <div className="w-40 h-[1px] bg-white lg:hidden"></div>
              <div className="w-1 h-1 rounded-full bg-white"></div>
              <motion.div
                className="h-[246px] w-[1px] bg-white hidden lg:block"
                initial={{ height: '246px' }}
                whileInView={{ height: '400px' }}
                transition={{
                  delay: 0.4,
                  duration: 1,
                }}
              ></motion.div>
            </div>
            <div className="lg:w-1/2 flex justify-end items-center">
              <Link to='/whitepaper' className="download_our_whitepaper w-[311px]" onClick={scrollToTop}>
                <Button
                  label={t('home', { context: 'download_our_whitepaper' })}
                  color="white"
                  size="lg"
                />
              </Link>
            </div>
          </div>
        </section>
        <section className="bg-white">
          <div className="flex flex-col items-center pb-9 pt-14 md:pt-40 md:pb-20">
            <div className="relative w-full flex justify-center px-10 md:px-0">
              <h2 className="font-serif text-4xl md:text-6xl text-mogok-red flex absolute top-0 md:top-14 left-1/2 -translate-x-1/2">
                {t('meet')}{' '}
                <span className="relative">
                  <img
                    className="absolute w-[350px] left-0 -top-5"
                    src={redCircles}
                    alt="Red circles"
                  />
                  <span className="pl-3">{t('mogok')}</span>
                </span>
              </h2>
              <img
                className="pt-16 md:pt-0 w-full max-w-[673px]"
                src={realRuby}
                alt="Ruby"
              />
              <TextLoop />
            </div>
            <Link to='/mogok-ruby' className="font-serif uppercase text-mogok-red text-xl mt-16 pb-2 sm:pb-0 border-b border-mogok-red sm:border-none">
              {t('home', { context: 'more_information' })}
            </Link>
            <p className="text-sm text-mogok-red max-w-[235px] text-center mt-5 sm:mt-11 mb-24 md:mb-40">
              {t('home', { context: 'discovered' })}
            </p>
            <div className="flex items-center flex-wrap sm:flex-nowrap gap-6 sm:gap-12 px-4 md:px-0">
              <div className="flex flex-col gap-6 w-full">
                <h3 className="text-sm text-mogok-red">{t('certified_by')}</h3>
                <div className="flex sm:hidden items-center w-full">
                  <div className="w-24 h-[1px] bg-mogok-red"></div>
                  <div className="w-1 h-1 rounded-full bg-mogok-red"></div>
                </div>
              </div>
              <div className="hidden sm:flex flex-col items-center h-full">
                <div className="w-1 h-1 rounded-full bg-mogok-red"></div>
                <div className="h-24 w-[1px] bg-mogok-red"></div>
              </div>
              <img className="w-44" src={giaLogo} alt="GIA logo" />
              <p className={"text-sm text-[#333333] font-medium " + (i18n.language === "ja-JP" && 'w-64')}>
                {t('home', { context: 'gemological' })}
                {i18n.language !== "ja-JP" &&
                  <>
                    <br />
                    {t('home', { context: 'institute' })}
                    <br />
                    {t('home', { context: 'america' })}
                  </>
                }
              </p>
            </div>
          </div>
        </section>
        <section className="pt-16 pb-12 md:pt-28 md:pb-24">
          <div className="mogok-container px-4 xl:px-0">
            <h2 className="font-serif text-4xl md:text-6xl pl-4 md:pl-0 text-white relative mb-16 md:mb-24">
              <span className="flex w-2 h-2 md:w-3 md:h-3 rounded-full bg-white absolute -top-[6px] left-[6px] md:-top-4 md:-left-4"></span>
              {t('home', { context: 'lastest_news' })}
            </h2>
            <div className="flex gap-5 justify-between">
              {newsData.news.map((article, index) => {
                return (
                  <div key={index}>
                    <NewsPost list={false} data={article} />
                  </div>
                );
              })}
            </div>
            <div className="flex justify-center md:justify-end mt-14 md:mt-10">
              <div className="flex flex-col items-center group">
                <MogokButton
                  color="transparent"
                  size="xl"
                  onClick={openNewsModal}
                >
                  {t('home', { context: 'view_all_news' })}
                </MogokButton>
                <div className="w-full md:w-0 h-[1px] bg-white transition-all duration-300 group-hover:w-full"></div>
              </div>
              <NewsModal />
            </div>
          </div>
        </section>
      </div>
    </Flowbite>
  );
};

export default Home;
