import { atom } from 'recoil';

const TeamSliderState = atom({
  key: 'TeamSliderState',
  default: {
    activeIndex: 0
  }
});

export default TeamSliderState;
