import React, { FC, useEffect, useMemo, useState, useRef } from 'react';
import { Navbar as MogokNavbar } from 'flowbite-react';
import { Link, useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';
import { useRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';

import NavigationLink from '../NavigationLink/NavigationLink';
import Button from '../Button/Button';
import PioneerNavbar from "../PioneerNavbar/PioneerNavbar"
import NavbarState from '../../../recoil/NavbarState';
import LangSwitcher from '../LangSwitcher/LangSwitcher';

import logo from '../../../assets/img/mogok-logo.svg';

interface NavbarProps { }


const Navbar: FC<NavbarProps> = ({ colorScheme }) => {
  const [btnLangSwitcherClicked, setBtnLangSwitcherClicked] = useState(false);
  const DropDonwnRef = useRef<HTMLButtonElement | null>(null);
  const { t } = useTranslation();
  const [navbarState, setNavbarState] = useRecoilState(NavbarState);
  const location = useLocation();
  const [positionX, setPositionX] = useState(0);
  const itemRefs = useRef([]);



  const pages: Array<object> = useMemo(() => {
    return [
      { path: '/', label: t('menu', { context: 'home' }), pos: 0, type: 'internal' },
//      { path: '/buy', label: t('menu', { context: 'buy' }), pos: 87, type: 'internal' },
      { path: '/mogok-ruby', label: t('menu', { context: 'mogok_ruby' }), pos: 198, type: 'internal' },
      { path: '/whitepaper', label: t('menu', { context: 'whitepaper' }), pos: 340, type: 'internal' },
      { path: '/team', label: t('menu', { context: 'team' }), pos: 463, type: 'internal' },
      { path: 'https://explore.mogok.io', label: t('menu', { context: 'explorer' }), pos: 564, type: 'external' },
      { path: '/contact', label: t('menu', { context: 'contact' }), pos: 676, type: 'internal' },
    ];
  }, [t])

  const handleButtonClick = (e: any) => {
    if (e.target.nodeName !== "BUTTON") {
      setBtnLangSwitcherClicked(!btnLangSwitcherClicked);
    }
  };


  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    const rect = event.currentTarget.getBoundingClientRect();
    const x = rect.x + rect.width / 2 + 26;
    setPositionX(x);
  };

  const mobileMenuHandler = () => {
    setNavbarState((ns) => {
      return { ...ns, open: !navbarState.open };
    });
  };

  const pageLinksClickHandler = (event: any, pos: number) => {
    handleClick(event)
    setNavbarState((ns) => {
      return { ...ns, open: false, activeBarPos: positionX };
    });
  }

  useEffect(() => {
    const findActivePageIndex = () => {
      return pages.findIndex(element => {
        if (element.type === 'internal') {
          return location.pathname === element.path;
        }
        return false;
      });
    };

    const updateActiveBarPosition = (index) => {
      if (itemRefs.current[index]) {
        const rect = itemRefs.current[index].getBoundingClientRect();
        const x = rect.x + rect.width / 2 + 26;
        setPositionX(x);
      }
    };

    const activePageIndex = findActivePageIndex();
    if (activePageIndex !== -1) {
      updateActiveBarPosition(activePageIndex);
    }

  }, [location, pages]);
  useEffect(() => {
    function handleClickOutside(event) {
      if (DropDonwnRef.current && DropDonwnRef.current.contains(event.target)) {
        setBtnLangSwitcherClicked(false);
      }
    }

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <header className='relative z-50'>
      {
        location.pathname !== "/pioneer-program" ?
          <MogokNavbar fluid={true} rounded={false}>
            <Link className='nav_bar_ja_logo flex items-center max-w-[194px] py-8 relative z-10' to='/'>
              <img src={logo} alt='Mogok Logo' />
            </Link>
            <button
              className='block relative w-7 h-6 z-10 lg:hidden overflow-hidden'
              onClick={mobileMenuHandler}
            >
              <motion.div
                className='w-7 h-[2px] bg-white absolute top-0 right-0'
                animate={navbarState.open ? {
                  x: -40,
                  opacity: 0
                } : {
                  x: 0,
                  opacity: 1
                }}
              ></motion.div>
              <motion.div
                className='w-7 h-[2px] bg-white absolute top-1/2 -translate-y-1/2 left-0'
                animate={navbarState.open ? {
                  opacity: 0
                } : {
                  opacity: 1
                }}
              ></motion.div>
              <motion.div
                className='w-7 h-[2px] bg-white absolute bottom-0 left-0'
                animate={navbarState.open ? {
                  x: 40,
                  opacity: 0
                } : {
                  x: 0,
                  opacity: 1
                }}
              ></motion.div>
              <motion.div
                className='w-7 h-[2px] bg-white rotate-45 absolute  top-1/2 -translate-y-1/2 opacity-0'
                animate={navbarState.open ? {
                  x: 0,
                  y: 0,
                  rotate: 45,
                  opacity: 1
                } : {
                  x: -40,
                  y: -40,
                  rotate: 45,
                  opacity: 0
                }}
              ></motion.div>

              <motion.div
                className='w-7 h-[2px] bg-white -rotate-45 absolute top-1/2 -translate-y-1/2 right-0'
                animate={navbarState.open ? {
                  x: 0,
                  y: 0,
                  rotate: -45,
                  opacity: 1
                } : {
                  x: 40,
                  y: -40,
                  rotate: -45,
                  opacity: 0
                }}
              ></motion.div>
            </button>
            <MogokNavbar.Collapse className={navbarState.open ? 'flex flex-1' : 'hidden'}>
              <div className='flex w-full items-center mb-3 lg:hidden'>
                <div className='w-full h-[1px] bg-white'></div>
                <div className='h-1 w-1 bg-white rounded-full'></div>
              </div>
              {
                pages.map((element: any, index: number) => {
                  return (
                    element.type === 'internal' ?
                      <div key={index} ref={el => itemRefs.current[index] = el} ><NavigationLink type='navbar' to={element.path} >
                        <span onClick={(e) => pageLinksClickHandler(e, element.pos)}>{element.label}</span>
                      </NavigationLink></div>
                      : <a key={index} ref={el => itemRefs.current[index] = el} href={element.path} target='_blank' rel="noreferrer" className='block md:p-0 text-white flex h-full items-center text-xl lg:text-base hover:text-white lg:hover:text-mogok-deep-red'>{element.label}</a>
                  )
                })
              }
              <div className='flex w-full h-auto items-center mt-1 lg:hidden'>
                <div className='w-full h-[1px] bg-white'></div>
                <div className='h-1 w-1 bg-white rounded-full'></div>
              </div>
              <div ref={DropDonwnRef} onClick={(e) => handleButtonClick(e)}>
                <LangSwitcher />
              </div>
              <div className={`flex w-full h-auto items-center mt-1 lg:hidden ${btnLangSwitcherClicked ? "mt-32" : ""}`}>
                <div className='w-full h-[1px] bg-white'></div>
                <div className='h-1 w-1 bg-white rounded-full'></div>
              </div>
              <Link to='/buy' className='hidden w-[300px] flex lg:hidden'>
                <Button label='Available now for Sale!' color='white' size='lg' />
              </Link>
            </MogokNavbar.Collapse>
            <div className='hidden max-w-full w-full absolute bottom-0 right-12 lg:flex'>
              <motion.div
                className='w-12 h-[2px] bg-white'
                animate={{
                  x: positionX
                }}
              ></motion.div>
            </div>
          </MogokNavbar>
          : <PioneerNavbar />}
    </header>
  );
}

export default Navbar;
