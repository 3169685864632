import React, { FC } from 'react';
import FAQAccordion from '../FAQAccordion/FAQAccordion';

import { ArrowLongRightIcon } from '@heroicons/react/24/outline';


const FAQSection: FC = () => {
  const data = [
    {
      "title": "モゴック パイオニアプログラムとは何ですか？",
      "content": "モゴック パイオニアプログラムは、初期サポーターの皆様に独占的な特典を提供するための階層的報酬制度です。メンバーは、貢献度に応じてプラチナ、ゴールド、シルバーの各レベルに分類され、特定のNFT数のボーナスや特別イベントへの招待など、独自の特典を享受できます。",
    },
    {
      "title": "どのようにしてモゴック パイオニアプログラムの会員になれますか？",
      "content": "会員になるには、特定数のNFTを購入または一定額の金銭的貢献をする必要があります。プラチナレベルは200枚以上のNFTまたは30,000ドル以上、ゴールドレベルは100枚以上のNFTまたは15,000ドル以上、シルバーレベルは50枚のNFTまたは7,500ドルの貢献が必要です。これらの基準を満たした後にプログラムに登録すると、特典を受け取ることができます。",
    },
    {
      "title": "パイオニア会員のNFTボーナスの利点は何ですか？",
      "content": "パイオニア会員は、サポートへの感謝として追加のNFTをボーナスとして受け取ります。プラチナ会員は10%増、ゴールド会員は5%増のNFTが付与されます。これらのボーナスは会員様への謝意として付加価値を提供し、NFTの保有を増やすものです。",
    },
    {
      "title": "パイオニア会員専用のイベントはありますか？",
      "content": "はい、パイオニア会員はプロジェクトやコミュニティとの繋がりを深めるためのモゴック専用イベントへの特別アクセス権を得られます。プラチナ会員には、年に1〜2回、創設チームとの特別ディナーパーティーへの特権もあります。",
    },
    {
      "title": "ホワイトリストとは何ですか？ パイオニア会員にとってのメリットは？ ",
      "content": "ホワイトリストは、パイオニア会員様が一般公開前に新しいNFTのリリースに早期アクセスできるようにするリストです。ホワイトリストに記載されることで、プレセール期間中に最新のNFTを購入する機会を確保し、新作の所有権を確保することができます。",
    },
  ];

  return (
    <section className='pb-16 pt-9 md:pt-24 md:pb-36'>
      <div className="mogok-container px-4 lg:px-0">
        <h2 className='font-serif text-4xl lg:text-6xl text-white relative mb-9 pl-3 lg:pl-0  lg:mb-12'>
          <span className='flex w-2 h-2 lg:w-3 lg:h-3 rounded-full bg-white absolute top-0 left-0 lg:-top-4 lg:-left-4'></span>
          よくある質問（FAQ)
        </h2>
        <p className='text-white max-w-[443px] mb-16 lg:mb-28'>ご質問に対する回答はこちらでご覧になれます。お探しの情報が見つからない場合は、直接お問い合わせください。また、テレグラムチャンネルでのご連絡も受け付けております。</p>
        <div>
          <FAQAccordion data={data} />
        </div>
        <div className='flex justify-center md:justify-end md:ml-auto md:mt-28 mt-9'>
          <a href="https://app.uniswap.org/#/tokens/polygon/0xc37cca16ff2db4c82021d3a5bead3796071ecfcb" rel="noreferrer" target="_blank" className='group flex items-center gap-3 mb-12 py-2 px-4 hover:bg-white  bg-mogok-deep-red  ease-in duration-150 rounded-full font-serif text-2xl text-white hover:text-mogok-red'>
            <span className='hidden md:block'>今すぐ購入</span>
            <span className='md:hidden block'>今すぐ購入</span>
            <ArrowLongRightIcon className='w-8 h-8 text-white group-hover:text-mogok-red' />
          </a>
        </div>

      </div>
    </section>
  );
}

export default FAQSection;
