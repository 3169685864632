import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';


import ContactForm from '../../components/ContactForm/ContactForm';

import GrayGem from '../../assets/img/gray-gem.png';
import RedDotNetwork from '../../assets/img/red-dot-network.svg';
import EclipsCirclesRed from '../../assets/img/eclipse-circles-red.svg';

interface ContactProps {}

const Contact: FC<ContactProps> = () => {
  const [t , i18n]= useTranslation();
  return(
    <div className="flex lg:gap-40 lg:flex-row flex-col-reverse py-12 md:py-24 overflow-hidden">
      <div className="lg:w-1/2 relative">
        <img src={RedDotNetwork} alt='dots' className="top-0 left-0 w-full object-cover object-center" />
        <img src={GrayGem} alt='mogok ruby' className="absolute top-4 -left-6 lg:-left-12 w-full object-cover object-center" />         
      </div>
      <section className="sectionHeight flex items-center w-full lg:w-1/2">
        <div className="max-w-full lg:max-w-2xl w-full mr-auto px-5">
          <div className="relative mogok-container lg:mx-auto mb-16">
            <div className='w-2 h-2 md:w-3 md:h-3 rounded-full bg-mogok-red absolute top-0 md:-top-6 left-0 md:-left-4'></div>
            <span className="font-serif text-4xl md:text-[80px] text-start z-10 text-mogok-red pl-3 md:pl-0 flex">
              {t('contact', { context: 'title_lats' })}
              <span className='ml-4 relative'>
                <img className='absolute left-0 top-0 md:-top-6 w-[300px]' src={EclipsCirclesRed} alt='red circles' />
                {i18n.language !== "ja-JP" && t('contact', { context: 'title_talk' })}
              </span>
            </span>
          </div>
          <ContactForm />
        </div>
      </section>
    </div>
  )
}

export default Contact;
