import React, { FC } from 'react';
import { RecoilRoot } from 'recoil';
import { Routes, Route } from 'react-router-dom';

import PageLayout from './components/common/PageLayout/PageLayout';
import Home from './pages/Home/Home';
import Buy from './pages/Buy/Buy';
import MogokRuby from './pages/MogokRuby/MogokRuby';
import Whitepaper from './pages/Whitepaper/Whitepaper';
import Team from './pages/Team/Team';
import Contact from './pages/Contact/Contact';
import PrivacyPolicy from './pages/PrivacyPolicy/PrivacyPolicy';
import PioneerProgram from './pages/PioneerProgram/PioneerProgram';

import './Mogok.css';

localStorage.theme = 'light';

const Mogok: FC = () => {
  return (
    <RecoilRoot>
      <PageLayout>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/buy" element={<Buy />} />
          <Route path="/mogok-ruby" element={<MogokRuby />} />
          <Route path="/whitepaper" element={<Whitepaper />} />
          <Route path="/team" element={<Team />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/pioneer-program" element={<PioneerProgram />} />
        </Routes>
      </PageLayout>
    </RecoilRoot>
  );
};

export default Mogok;
