import { atom } from 'recoil';

const NewsState = atom({
  key: 'NewsState',
  default: {
    opened: false,
    type: 'list',
    selectedSlug: null,
  },
});

export default NewsState;
