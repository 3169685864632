import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Footer as MogokFooter } from 'flowbite-react';
import { useTranslation } from 'react-i18next';

import NavigationLink from '../NavigationLink/NavigationLink';
import Button from '../../common/Button/Button';

import logo from '../../../assets/img/footer-logo.svg';
import giaLogo from '../../../assets/img/GIA.png';
import openseaLogo from '../../../assets/img/opensea.png';
import uniswapLogo from '../../../assets/img/uniswap.png';

interface FooterProps { }

const Footer: FC<FooterProps> = () => {
  const { t, i18n } = useTranslation();

  let pages: Array<object> = [
    { path: '/', label: t('menu', { context: 'home' }), type: 'internal' },
//    { path: '/buy', label: t('menu', { context: 'buy' }), type: 'internal' },
    { path: '/mogok-ruby', label: t('menu', { context: 'mogok_ruby' }), type: 'internal' },
    { path: '/whitepaper', label: t('menu', { context: 'whitepaper' }), type: 'internal' },
    { path: '/team', label: t('menu', { context: 'team' }), type: 'internal' },
    { path: 'https://explore.mogok.io', label: t('menu', { context: 'explorer' }), type: 'external' },
    { path: '/contact', label: t('menu', { context: 'contact' }), type: 'internal' },
  ];

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <MogokFooter>
      <div className="w-full flex flex-col gap-16 lg:gap-32">
        <div className="w-full flex flex-col gap-16 lg:gap-0 lg:flex-row lg:justify-between">
          <Link to='/'>
            <img src={logo} alt='Mogok Logo' />
          </Link>
          <div className={'w-full ' + (i18n.language !== "ja-JP" ? 'max-w-sm' : 'max-w-xl')}>
            <MogokFooter.LinkGroup className='grid grid-cols-2 gap-x-11 gap-y-4'>
              {pages.map((element: any, index: number) => {
                return (
                  element.type === 'internal' ?
                    <div key={index} onClick={scrollToTop}>
                      <NavigationLink key={index} type='footer' to={element.path}>
                        {element.label}
                      </NavigationLink>
                    </div>
                    : <a key={index} href={element.path} target='_blank' rel="noreferrer" className='font-serif text-xl text-mogok-red ml-0 transition-all duration-300 hover:ml-4 hover:text-mogok-deep-red'>{element.label}</a>
                );
              })}
            </MogokFooter.LinkGroup>
          </div>
          <div className="browse_and_buy max-w-[352px] w-full">
            <MogokFooter.Title className="hidden" title={t('browse_and_buy')} />
            <div className='hidden flex items-center w-full my-6'>
              <div className='w-full h-[1px] bg-mogok-red'></div>
              <div className='w-1 h-1 bg-mogok-red rounded-full'></div>
            </div>
            <MogokFooter.LinkGroup className="hidden" col={true}>
              <a href='https://opensea.io/collection/mogok' target='_blank' rel="noreferrer"><Button label={t('on_opensea', { ns: 'common' })} color='red' size='lg' icon={openseaLogo} /></a>
              <a href='https://app.uniswap.org/#/tokens/polygon/0xc37cca16ff2db4c82021d3a5bead3796071ecfcb' target='_blank' rel="noreferrer"><Button label={t('on_uniswap', { ns: 'common' })} color='red' size='lg' icon={uniswapLogo} /></a>
            </MogokFooter.LinkGroup>
          </div>
        </div>
        <div className='flex flex-col gap-12'>
          <ul className='flex gap-8'>
            <li><a className='font-serif uppercase text-sm text-mogok-red hover:underline' href='/'>Twitter</a></li>
            <li><a className='font-serif uppercase text-sm text-mogok-red hover:underline' href='/'>Linkedin</a></li>
          </ul>
          <div className="w-full flex gap-6 md:gap-0 md:items-center sm:justify-between flex-col-reverse md:flex-row">
            <div className='flex items-center gap-2'>
              <span className='text-mogok-gray text-xs'>{t('all_rights_reserved')}</span>
              <div className='w-[2px] h-[2px] rounded-full bg-mogok-gray'></div>
              <span className='text-mogok-gray text-xs'>© 2023</span>
              <div className='w-[2px] h-[2px] rounded-full bg-mogok-gray'></div>
              <span className='text-mogok-gray text-xs'>Mogok.io</span>
              <div className='w-[2px] h-[2px] rounded-full bg-mogok-gray'></div>
              <Link to='/privacy-policy' className='text-mogok-red text-xs' onClick={scrollToTop}>{t('privacy')} {t('policy')}</Link>
            </div>
            <div className="flex items-center gap-4">
              <span className='text-mogok-gray text-xs'>{t('certified_by')}</span>
              <img className='max-w-[105px]' src={giaLogo} alt='GIA Logo' />
            </div>
          </div>
        </div>
      </div>
    </MogokFooter>
  );
};

export default Footer;
