import React from 'react';
import { motion } from 'framer-motion';
import { useScroll, useTransform } from 'framer-motion';

import ruby from '../../assets/img/ruby-image.png';
import cube1 from '../../assets/img/cube1.png';
import cube2 from '../../assets/img/cube2.png';
import cube3 from '../../assets/img/cube3.png';

import { ArrowLongRightIcon, ChevronDoubleDownIcon } from '@heroicons/react/24/outline';


const MogokPionnerProgram = () => {

  const { scrollY } = useScroll();

  const moveToTop = useTransform(
    scrollY,
    [0, 80],
    [0, -190],
  )
  const rotate = useTransform(
    scrollY,
    [0, 100],
    [0, 90],
  )

  const animatinContainer = {
    animate: {
      rotate: 0,
      transition: {
        duration: 1,
        ease: "easeIn"
      }
    }
  };

  const rotateCube1 = {
    animate: {
      rotate: [0, -25, 0],
      x: [-20, 0, 0, -20],
      y: [0, -10, -15, 0],
      transition: {
        repeat: Infinity,
        duration: 9,
        delay: .2,
        ease: 'easeInOut',

      }
    }
  };

  const rotateCube2 = {
    animate: {
      rotate: [0, 25, 0],
      x: [0, 20, 0],
      y: [0, 30, 0],
      transition: {
        repeat: Infinity,
        duration: 9,
        ease: 'easeInOut',


      }
    }
  };

  const rotateCube3 = {
    animate: {
      rotate: [0, -20, 0],
      x: [0, -20, -15, 0],
      y: [0, -15, 0],
      transition: {
        repeat: Infinity,
        duration: 9,
        ease: 'easeInOut',

      }
    }
  };





  return (
    <motion.div >
      <motion.div
        className='flex justify-end pl-auto w-1/3 md:w-[200px] pt-20 md:pt-10 mb-4 md:-mb-16 ml-2 md:ml-[500px]'
        variants={animatinContainer}
        style={{ y: moveToTop, rotate: rotate, }}
        animate="animate"
        transition={{
          duration: 5,
        }}
      >
        <motion.div
          variants={rotateCube1}
        >
          <img src={cube1} alt='Cube' className='w-[120px] md:w-[180px]' />
        </motion.div>
      </motion.div>
      <div className='flex flex-col mt-0 md:mt-5  items-center text-white'>
        <div className='relative flex  w-full justify-center px-4'>
          <img className='w-[311px] md:h-[280px] lg:w-full lg:max-w-lg ' src={ruby} alt='Mogok Ruby' />
          <h1 className='font-serif text-center text-white text-5xl  w-[300px] md:w-full  md:text-[80px] absolute top-1/2 md:top-1/3 -translate-y-1/2'>モゴックのパイオニアプログラム</h1>
        </div>
        <motion.div
          variants={animatinContainer}
          animate="animate"
          transition={{
            duration: 5,
          }}
          style={{ y: moveToTop, rotate: rotate }}
          className='flex justify-end md:justify-center md:ml-auto  w-[100px] md:w-1/4  -mt-10 md:mt-5 ml-[390px] md:mr-[200px] md:pl-[0px] '
        >
          <motion.div
            variants={rotateCube2}
          >
            <img src={cube3} alt='Cube' className='' />
          </motion.div>
        </motion.div>
        <p className='text-center z-20 text-base md:text-xl md:max-w-[550px] max-w-[280px] mb-6 md:mb-12 '>
        モゴックの物語の先駆者（パイオニア）として、この冒険に参加しましょう。皆様のご支援により、私たちは未来の人々にとって記憶に残るプロジェクトを実現することができます。 モゴックの目標は、日本におけるユニークな物理資産のトークン化について話すとき、そしておそらく海外でも、思い浮かぶ例となることです。
        </p>
        <a href="https://app.uniswap.org/#/tokens/polygon/0xc37cca16ff2db4c82021d3a5bead3796071ecfcb" target="_blank" rel="noreferrer" className='group flex items-center gap-3 mb-12 py-2 px-4 hover:bg-white  bg-mogok-deep-red  ease-in duration-150 rounded-full font-serif text-2xl text-white hover:text-mogok-red'>
          今すぐ購入
          <ArrowLongRightIcon className='w-8 h-8 text-white group-hover:text-mogok-red' />
        </a>
        <span className='font-medium mb-4 z-20 '>詳細はスクロールしてご覧ください</span>
        <ChevronDoubleDownIcon className='w-6 h-6 animate-bounce ' />
        <motion.div
          variants={animatinContainer}
          animate="animate"
          transition={{
            duration: 5,
          }}
          style={{ y: moveToTop, rotate: rotate }}
          className='hidden md:flex md:justify-center mr-auto md:w-1/3 -ml-[90px] md:ml-40 -mt-[220px]'>
          <motion.div
            variants={rotateCube3}
          >
            <img src={cube2} alt='Cube' className='w-[270px] h-[270px]' />
          </motion.div>
        </motion.div>
        <motion.div className='md:hidden flex md:justify-center mr-auto md:w-1/3 -ml-[110px] md:ml-0 -mt-[220px]'>
          <img src={cube2} alt='Cube' className='w-[270px] h-[270px]' />
        </motion.div>
      </div >
    </motion.div >
  )
}

export default MogokPionnerProgram;
