import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';

import logo from '../../../assets/img/mogok-logo.svg';
import logoWithoutText from '../../../assets/img/mogok-logo-without-text.svg';

import { ArrowLongRightIcon } from '@heroicons/react/24/outline';


const PioneerNavbar = () => {
  const [scrolling, setScrolling] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      setScrolling(scrollTop > 50);
    };
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  return (
    <div className={`fixed z-50 w-full  top-0 flex justify-between py-4 pl-4 md:pl-12  md:pr-8   ${scrolling ? 'bg-mogok-red drop-shadow-xs' : 'bg - transparent'} `}>
      < Link className='nav_bar_ja_logo flex items-center max-w-[194px]relative z-10' to='/' >
        <img src={logo} alt='Mogok Logo' className='hidden md:block' />
        <img src={logoWithoutText} alt='Mogok Logo' className='block md:hidden' />
      </ Link>
      <Link to=''>
        <button className='flex items-center gap-4 py-2 px-4  rounded-full font-serif font-bold text-white  ease-in duration-150 hover:bg-mogok-deep-red'>
          メインサイトへ
          <ArrowLongRightIcon className='w-8 h-8 text-white' />
        </button>
      </Link>
    </div >
  )
}

export default PioneerNavbar
