import React, { FC } from 'react';
import { useSetRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import { Flowbite, CustomFlowbiteTheme, Card, Button } from 'flowbite-react';
import { ChevronRightIcon } from '@heroicons/react/24/outline';

import NewsState from '../../recoil/NewsState';

interface NewsPostProps {
  list: boolean;
  data: [];
}

const NewsPost: FC<NewsPostProps> = ({ list, data }) => {
  const setNewsState = useSetRecoilState(NewsState);
  const { t } = useTranslation();

  const theme: CustomFlowbiteTheme = {
    card: {
      root: {
        base: 'flex w-full' + (list ? ' p-4' : ' max-w-[523px]'),
        children: 'flex flex-col gap-9 md:gap-12',
      }
    },
    button: {
      base: 'group flex h-min items-center text-white font-serif focus:ring-transparent',
      color: {
        red: '!text-mogok-red hover:underline',
        white: 'text-white hover:underline'
      },
      size: {
        md: 'text-sm uppercase',
      }
    }
  }

  const readMoreClickHandler = () => {
    setNewsState((nms) => {
      return {
        ...nms,
        opened: true,
        newsLoaded: true,
        type: 'post',
        selectedNews: null,
        selectedSlug: data.Slug,
        title: data.Title,
      };
    });
  };

  return (
    <Flowbite theme={{ theme }}>
      <Card>
        <div className={'flex gap-6 md:gap-9 h-full' + (list ? ' lg:w-auto w-[373px] gap-4' : ' flex-col md:flex-row ')}>
          <img className={'rounded-lg object-cover' + (list ? ' w-[71px] h-[71px] lg:w-44 lg:h-[120px] flex self-center' : ' w-full h-[153px] md:w-60 md:h-[300px]')} src={data.Top_Drive_Item !== undefined ? data.Top_Drive_Item.View_Url : null} alt='News Post' />
          <div className={'md:py-6 flex justify-between' + (list ? ' gap-9 w-full' : ' flex-col')}>
            <div className={'flex' + (list ? ' gap-9 items-center' : ' flex-col gap-5')}>
              <div className={'flex flex-col' + (list ? ' gap-4 max-w-[170px]' : ' gap-5')}>
                <div><span className='py-1 px-2 bg-mogok-deep-red rounded font-semibold text-xs text-white'>{new Date(parseInt(data.Published.unixms)).toLocaleDateString('ru-RU')}</span></div>
                <h2 className={'font-serif' + (list ? ' text-mogok-red text-sm lg:text-xl' : ' text-xl text-white')}>{data.Title}</h2>
              </div>
              <p className={'mt-1 text-sm' + (list ? ' text-mogok-gray max-w-[218px] hidden lg:block' : ' text-white')}>
                {data.Short_Contents}
              </p>
            </div>
            <div className={list ? 'flex items-center' : ''}>
              <button className='block lg:hidden'>
                <ChevronRightIcon className='text-mogok-red w-6' />
              </button>
              <div className={list ? 'hidden lg:block' : ''}>
                <Button color={list ? 'red' : 'white'} onClick={readMoreClickHandler}>{t('read_more', { ns: 'common' })}</Button>
              </div>
            </div>
          </div>
        </div>
        {
          !list ?
            <div className='flex items-center w-full'>
              <div className='w-full h-[1px] bg-white'></div>
              <div className='w-1 h-1 bg-white rounded-full'></div>
            </div> :
            <></>
        }
      </Card>
    </Flowbite>
  );
};

export default NewsPost;
