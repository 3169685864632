import React, { FC } from 'react';

import TeamPageTabs from '../../components/TeamPageTabs/TeamPageTabs';
import TeamSlider from '../../components/TeamSlider/TeamSlider';

interface TeamProps {}

const Team: FC<TeamProps> = () => {
  return (
    <>
      <TeamPageTabs />
      <TeamSlider />
    </>
  );
};

export default Team;
