import { FC } from 'react';
import { Flowbite } from 'flowbite-react';

import Navbar from '../Navbar/Navbar.tsx';
import Footer from '../Footer/Footer';

import theme from '../../../theme/theme';

interface PageLayoutProps {
  children: JSX.Element
}

const PageLayout: FC<PageLayoutProps> = ({ children }) => {

  return (
    <Flowbite theme={theme}>
      <Navbar />
      {children}
      <Footer />
    </Flowbite>
  );
};

export default PageLayout;

