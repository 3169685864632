import {atom} from "recoil"

const formContactState = atom({
    key: 'formContactState', 
    default: {
        success: false,
        data:{
            Name:'',
            Email:'',
            Subject:'',
            Message:'',
            },   
        error:'',
        loading:false
    }, 
  });

  export default formContactState;