import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';


import circles from '../../assets/img/text-circles.svg';

interface IntroQuoteProps { }

const IntroQuote: FC<IntroQuoteProps> = () => {
  const { t, i18n } = useTranslation();

  return (
    <h1 className='font-serif text-5xl mx-auto md:m-0 md:text-7xl text-white flex flex-col md:items-baseline items-center'>

      {
        i18n.language === 'ja-JP' ?
          <span className='ml-3 relative'>
            <img className='absolute left-0 md:left-4 -top-2 w-56 md:-top-8 md:w-80' src={circles} alt='crypto circles' />
            <span className='text-white'>{t('buy', { context: 'title1' })}</span>
          </span> :
          <span className={`buy_title1 drop-shadow-border text-mogok-red `}>{t('buy', { context: 'title1' })}</span>

      }
      <span>{t('buy', { context: 'title2' })}</span>
      <span className='relative md:w-auto flex items-center'>
        <span className={i18n.language === 'ja-JP' ? 'buy_title1 drop-shadow-border text-mogok-red' : ''}>{t('buy', { context: 'title3' })}</span>
        {
          i18n.language === 'ja-JP' ?
            <></> :
            <span className='ml-3 relative'>
              <img className='absolute left-0 md:-left-4 -top-2 w-56 md:-top-4 md:w-80' src={circles} alt='crypto circles' />
              <span>{t('buy', { context: 'title4' })}</span>
            </span>
        }

      </span>
    </h1>
  );
};

export default IntroQuote;
