import { atom } from 'recoil';

const NewsDataState = atom({
  key: 'NewsDataState',
  default: {
    news: [],
  }
})

export default NewsDataState;
