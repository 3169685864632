import { FC } from 'react';
import { ChevronDoubleDownIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import parse from 'html-react-parser';

import NewsSinglePostState from '../../recoil/NewsSinglePost';

interface NewsSinglePostProps { }

const NewsSinglePost: FC<NewsSinglePostProps> = () => {
  const singlePost = useRecoilValue(NewsSinglePostState);
  const { t } = useTranslation();

  if (singlePost.selectedNews === null) return;
  return (
    <div className="flex flex-col lg:flex-row gap-6 lg:gap-12">
      <div className="w-full h-28 lg:w-56 lg:h-auto rounded-lg overflow-hidden relative">
        <img
          className="w-full h-full object-cover"
          src={
            singlePost.selectedNews.Top_Drive_Item !== undefined
              ? singlePost.selectedNews.Top_Drive_Item.View_Url
              : null
          }
          alt="Article"
        />
        <div className="w-full flex justify-between items-center absolute top-0 left-0 py-3 px-2">
          <div className="block lg:hidden">
            <span className="py-1 px-2 bg-mogok-deep-red rounded font-semibold text-sm text-white">
              {new Date(
                parseInt(singlePost.selectedNews.Published.unixms)
              ).toLocaleDateString()}
            </span>
          </div>
        </div>
      </div>
      <div className="sm:w-[548px]">
        <div className="flex justify-between mb-6 lg:mb-9">
          <div className="flex flex-col gap-4 max-w-[195px]">
            <div className="hidden lg:block">
              <span className="py-1 px-2 bg-mogok-deep-red rounded font-semibold text-sm text-white">
                {new Date(
                  parseInt(singlePost.selectedNews.Published.unixms)
                ).toLocaleDateString()}
              </span>
            </div>
            <h2 className="font-serif text-xl text-mogok-red">
              {singlePost.selectedNews.Title}
            </h2>
          </div>
        </div>
        <div className="flex items-center">
          <div className="w-full bg-mogok-red h-[1px]"></div>
          <div className="w-2 h-2 bg-mogok-red rounded-full"></div>
        </div>
        <div className="mt-6 lg:mt-9 h-[155px] lg:h-auto overflow-y-scroll">
          <p className="text-mogok-dark-gray text-sm">
            {parse(singlePost.selectedNews.Contents)}
          </p>
        </div>
        <div className="flex flex-col items-center gap-1 mt-6">
          <span className="font-semibold text-mogok-red">{t('scroll', { ns: 'common' })}</span>
          <ChevronDoubleDownIcon className="text-mogok-red w-6 h-6" />
        </div>
      </div>
    </div>
  );
};

export default NewsSinglePost;
