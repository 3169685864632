import React, { FC } from 'react';
import { Accordion, AccordionProps, Flowbite } from 'flowbite-react';
import { FAQAccordionTheme } from './FAQAccordionTheme';


interface FAQAccordionProps extends AccordionProps {
  data: Array<{ title: string; content: string; }>
}

const FAQAccordion: FC<FAQAccordionProps> = ({ ...props }) => {
  return (
    <Flowbite theme={{ theme: FAQAccordionTheme }}>
      <Accordion {...props} collapseAll>
        {
          props.data.map((item, index) => {
            return (
              <Accordion.Panel key={index}>
                <Accordion.Title>
                  <div className='flex gap-7 justify-between w-full'>
                    <span className='text-base md:text-xl font-serif font-bold text-white text-left'>{item.title}</span>
                    <div className='plus-icon relative w-8 h-8'>
                      <span className='block absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[22px] h-[2px] bg-white'></span>
                      <span className='block absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[2px] h-[22px] bg-white transition-all duration-500 ease-in-out'></span>
                    </div>
                  </div>
                </Accordion.Title>
                <Accordion.Content>{item.content}</Accordion.Content>
              </Accordion.Panel>
            )
          })
        }
      </Accordion>
    </Flowbite>
  );
}

export default FAQAccordion;