import { atom } from 'recoil';

const NewsSinglePostState = atom({
  key: 'NewsSinglePostState',
  default: {
    title: '',
    selectedNews: null,
  },
});

export default NewsSinglePostState;
