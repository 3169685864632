import { FC } from 'react';
import { useRecoilState } from 'recoil';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';

import HowToBuySliderState from '../../recoil/HowToBuySliderState';

const HowToBuySlider: FC = () => {
  const { t } = useTranslation();
  const [sliderState, setSliderState] = useRecoilState(HowToBuySliderState);

  const navigateTo = (index: number) => {
    setSliderState((ss) => {
      return { ...ss, activeIndex: index }
    });
  }

  return (
    <div className='h-full flex flex-col justify-between'>
      <div className='overflow-hidden'>
        <div className={'flex transition-all duration-300 relative ' + (sliderState.activeIndex === 0 ? 'right-0' : '') + (sliderState.activeIndex === 1 ? 'right-full' : '') + (sliderState.activeIndex === 2 ? 'right-[200%]' : '')}>
          <div className='flex flex-col min-w-full'>
            <span className='text-sm text-white mb-4'>01</span>
            <h2 className='font-serif text-2xl text-white mb-5'>{t('buy_get_an', { context: 'mrt' })}</h2>
            <p className='text-white text-sm'>
              {t('buy_get_an', { context: 'mrt_desc' })}
            </p>
          </div>
          <div className='flex flex-col min-w-full'>
            <span className='text-sm text-white mb-4'>02</span>
            <h2 className='font-serif text-2xl text-white mb-5'>{t('buy_your', { context: 'nft' })}</h2>
            <p className='text-white text-sm'>
              {t('buy_your', { context: 'nft_desc' })}
            </p>
          </div>
          <div className='flex flex-col min-w-full'>
            <span className='text-sm text-white mb-4'>03</span>
            <h2 className='font-serif text-2xl text-white mb-5'>{t('buy_customize_your', { context: 'voxel' })}</h2>
            <p className='text-white text-sm'>
              {t('buy_customize_your', { context: 'voxel_desc' })}
            </p>
          </div>
        </div>
      </div>
      <div className='flex items-center justify-between'>
        <ul className='flex items-center gap-2 mt-6 lg:mt-0'>
          <li
            className={'flex w-2 h-2 rounded-full cursor-pointer transition-all duration-300 bg-mogok-red hover:bg-white' + (sliderState.activeIndex === 0 ? ' bg-white' : '')}
            onClick={() => navigateTo(0)}
          ></li>
          <li
            className={'flex w-2 h-2 rounded-full cursor-pointer transition-all duration-300 bg-mogok-red hover:bg-white' + (sliderState.activeIndex === 1 ? ' bg-white' : '')}
            onClick={() => navigateTo(1)}
          ></li>
          <li
            className={'flex w-2 h-2 rounded-full cursor-pointer transition-all duration-300 bg-mogok-red hover:bg-white' + (sliderState.activeIndex === 2 ? ' bg-white' : '')}
            onClick={() => navigateTo(2)}
          ></li>
        </ul>
        <div className='hidden gap-6 lg:flex'>
          {sliderState.activeIndex !== 0 ?
            <motion.button
              className='font-serif uppercase text-white'
              onClick={sliderState.activeIndex === 1 ? () => navigateTo(0) : () => navigateTo(1)}
            >{t('previous', { ns: 'common' })}</motion.button>
            : <></>
          }
          {sliderState.activeIndex !== 2 ?
            <button
              className='font-serif uppercase text-white'
              onClick={sliderState.activeIndex === 0 ? () => navigateTo(1) : () => navigateTo(2)}
            >{t('next', { ns: 'common' })}</button>
            : <></>
          }
        </div>
      </div>
    </div>
  )
}

export default HowToBuySlider;
