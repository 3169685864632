import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';


import HeroSection from '../../components/HeroSection/HeroSection';
import HowToBuySlider from '../../components/HowToBuySlider/HowToBuySlider';
import MogokToast from '../../components/common/MogokToast/MogokToast';


import cube from '../../assets/img/mogok-cube.png';
import bookIcon from '../../assets/img/bookIcon.svg'

interface BuyProps {}

const Buy: FC<BuyProps> = () => {

  const { t } = useTranslation();

  return (
    <div className='bg-mogok-red'>
      <MogokToast title='New documentation available!'
                  subtitle='It covers everything to start'
                  icon={<img src={bookIcon} alt='book icon'/>}
                  />
      <HeroSection isAnimating={true} />
      <section>
        <div className='mogok-container px-4 lg:px-0 py-11 lg:py-40'>
          <h2 className='font-serif text-4xl lg:text-6xl text-white relative mb-16 pl-3 lg:pl-0 lg:mb-24'>
            <span className='flex w-2 h-2 lg:w-3 lg:h-3 rounded-full bg-white absolute top-0 left-0 lg:-top-4 lg:-left-4'></span>
             {t('buy', { context: 'how_to_buy' })}
          </h2>
          <div className='rounded-lg overflow-hidden bg-mogok-deep-red flex flex-col gap-9 lg:gap-20 lg:flex-row'>
            <img className='h-44 lg:h-full w-full lg:w-[354px] object-cover' src={cube} alt='Mogok Cube' />
            <div className='pb-9 px-6 lg:py-9 lg:pr-20'>
              <HowToBuySlider />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Buy;
