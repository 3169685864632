import { CustomFlowbiteTheme } from 'flowbite-react';

export const FAQAccordionTheme: CustomFlowbiteTheme = {
  accordion: {
    root: {
      base: 'divide-y divide-gray-200 border-b border-white',
      flush: {
        off: '',
        on: '',
      },
    },
    content: {
      base: 'px-6 py-9 text-white font-medium',
    },
    title: {
      arrow: {
        base: 'hidden',
        open: {
          off: '',
          on: 'rotate-180',
        },
      },
      base: 'faq-accordion flex w-full items-center justify-between first:border-t last:border-b border-white p-6 font-serif font-bold text-lg',
      flush: {
        off: '',
        on: 'bg-transparent dark:bg-transparent',
      },
      heading: '',
      open: {
        off: '',
        on: 'faq-active',
      },
    },
  },
};
