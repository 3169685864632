import React, { FC } from 'react';
import { useRecoilState } from 'recoil';
import { rest } from '@karpeleslab/klbfw';
import { Label, TextInput, Flowbite, CustomFlowbiteTheme, Textarea } from 'flowbite-react';
import { useTranslation } from 'react-i18next';


import Button from '../common/Button/Button';
import formContactState from '../../recoil/FormContactState';


interface ContactFormProps { }

const theme: CustomFlowbiteTheme = {
  textInput: {
    field: {
      base: 'relative w-full',
      input: {
        base: 'block w-full border-none outline-none focus:ring-transparent',
      }
    }
  },
  textarea: {
    base: 'block w-full',
    colors: {
      red: 'border-none outline-none focus:ring-transparent',
    }
  },
  label: {
    "root": {
      "colors": {
        "default": "text-mogok-red mb-2 flex",
      }
    }
  },
}

const ContactForm: FC<ContactFormProps> = () => {

  const { t } = useTranslation();
  const [formContact, setFormContact] = useRecoilState(formContactState);

  const handleSubmit = (e: any) => {
    e.preventDefault();

    let preparedForm: any = { To: '@support' };
    let formData = new FormData(e.target);

    for (const pair of formData.entries()) {
      preparedForm[pair[0]] = pair[1];
    }

    setFormContact({ ...formContact, loading: true });

    rest('Support/Ticket', 'POST', preparedForm)
      .then(res => {
        setFormContact({ ...formContact, loading: false, success: res.result === 'success' ? true : false });
      }).catch(err => {
        setFormContact({ ...formContact, loading: false });
      });
  };

  return (
    <Flowbite theme={{ theme }}>
      <form className="flex flex-col gap-4 lg:gap-9" onSubmit={handleSubmit}>
        <div className='labelgroup'>
          <Label
            htmlFor="full_name"
            value={t('contact', { context: 'full_name' })}
            className='focus:ring-deep-red'
          />
          <TextInput
            id="full_name"
            name='Name'
            type="text"
            sizing="md"
            color='red'
          />
          <div className='flex items-center'>
            <div className='w-full h-[1px] bg-mogok-red'></div>
            <div className='w-1 h-1 bg-mogok-red rounded-full'></div>
          </div>
        </div>
        <div className='labelgroup'>
          <Label
            htmlFor="email"
            value={t('email', { ns: 'common' }) + ' *'}
            className='focus:ring-deep-red'
          />
          <TextInput
            id="email"
            name='Email'
            type="email"
            sizing="md"
            color='red'
          />
          <div className='flex items-center'>
            <div className='w-full h-[1px] bg-mogok-red'></div>
            <div className='w-1 h-1 bg-mogok-red rounded-full'></div>
          </div>
        </div>
        <div className='labelgroup'>
          <Label
            htmlFor="subject"
            value={t('subject', { ns: 'common' }) + ' *'}
            className='focus:ring-deep-red'
          />
          <TextInput
            id="subject"
            name='Subject'
            type="text"
            sizing="md"
            color='red'
          />
          <div className='flex items-center'>
            <div className='w-full h-[1px] bg-mogok-red'></div>
            <div className='w-1 h-1 bg-mogok-red rounded-full'></div>
          </div>
        </div>
        <div className='labelgroup'>
          <Label
            htmlFor="message"
            value={t('message', { ns: 'common' }) + ' *'}
            className='focus:ring-deep-red'
          />
          <Textarea
            id="message"
            name='Message'
            color='red'
            rows={5}
          />
          <div className='flex items-center'>
            <div className='w-full h-[1px] bg-mogok-red'></div>
            <div className='w-1 h-1 bg-mogok-red rounded-full'></div>
          </div>
        </div>
        {formContact.success && <p className='text-green-500'>{t('contact', { context: 'success_message' })} </p>}
        <div className='ml-auto mt-14'>
          <Button label={`${formContact.loading ? "Loading" : "Submit"}`}
            color={'red'}
            size='lg'
            loading={formContact.loading} />

        </div>
      </form>
    </Flowbite>
  )
}

export default ContactForm;
