import React, { FC, /**ReactNode,**/ useState, useEffect } from 'react'
//import { Flowbite, Toast, ToastProps as FlowbiteToastProps, CustomFlowbiteTheme } from 'flowbite-react';
//import { useTranslation } from 'react-i18next';

//import smallArrow from '../../../assets/img/smallArrow.svg'
/**
interface ToastProps extends FlowbiteToastProps {
  title: string;
  subtitle: string;
  icon: ReactNode;
}**/


const MogokToast: FC<ToastProps> = ({ title, subtitle, icon }) => {
//  const { t } = useTranslation();
  const [isVisible, setIsVisible] = useState(true);

/**  const theme: CustomFlowbiteTheme = {
    toast: {
      "root": {
        "base": `fixed z-50 right-1/2 translate-x-1/2  md:translate-x-0 md:right-0 bottom-4 md:bottom-16 w-[85%] md:w-[450px] items-center rounded-lg md:rounded-r-none bg-white p-4  text-gray-500 shadow dark:bg-gray-800 dark:text-gray-400 ease-in duration-1000 ${isVisible ? "" : "hidden"}`,
        "closed": "opacity-0 ease-out"
      },
      "toggle": {
        "base": "-mx-1.5 -my-1.5 ml-auto inline-flex h-8 w-8 rounded-l-lg bg-white p-1.5 text-gray-400 hover:bg-gray-100 hover:text-gray-900 focus:ring-2 focus:ring-gray-300 dark:bg-gray-800 dark:text-gray-500 dark:hover:bg-gray-700 dark:hover:text-white",
        "icon": "h-5 w-5 shrink-0 hidden"
      }
    }
  };**/

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 500 && isVisible) {
        setIsVisible(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isVisible]);

  return <></>;
  /** 
  return (
    <Flowbite theme={{ theme }}>
      <Toast>
        <div className='flex py-3 relative'>
          <div className='absolute left-[-40px]'>
            {icon}
          </div>
          <div >
            <div className={" ml-[90px] md:ml-32  mb-6 text-sm font-normal "}>
              <h2 className='text-mogok-deep-red md:text-base text-sm md:font-medium font-semibold'>{title}</h2>
              <h5 className='text-mogok-red md:text-sm text-xs'>{subtitle}</h5>
            </div>
            <div className='flex justify-end gap-3'>
              <button className='text-mogok-red hover:text-mogok-deep-red' onClick={() => setIsVisible(false)}>{t('close', { ns: 'common' })}</button>
              <button className='flex items-center gap-3 py-1 px-3 bg-mogok-red hover:bg-mogok-deep-red  rounded-full font-serif text-white'>{t('go_to_it', { ns: 'common' })}
                <img src={smallArrow} alt='arrow icon' />
              </button>
            </div>
          </div>
        </div>
      </Toast>
    </Flowbite>
  )**/
}

export default MogokToast
