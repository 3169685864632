import React, { FC, useCallback, useEffect, useRef, useState } from 'react'
import PionnerCard from '../PionnerCard/PionnerCard'
import { ArrowLongLeftIcon, ArrowLongRightIcon } from '@heroicons/react/24/outline';

import blockchainIcon from '../../assets/img/blockchain-icon.svg'
import bookmark from '../../assets/img/bookmark-icon.svg'

const PionnerCaroucel: FC = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [containerWidth, setContainerWidth] = useState(0);
  const [scrollLeftValue, setScrollLeftValue] = useState(0);
  const [slideValue, setSlideValue] = useState({ index: 0, value: 0 });
  const [maxSlides, setMaxSlides] = useState(0);
  const data = [
    {
      "number": "01",
      "title": "パイオニア会員レベル",
      "content": "献身に対する称賛として、寄与度に応じプラチナ・ゴールド・シルバーの階層をご用意。各レベルに見合った特典を。",
      "icon": blockchainIcon
    },
    {
      "number": "02",
      "title": "NFTプレミアムボーナス",
      "content": "会員レベルに応じた感謝の印。プラチナは10%、ゴールドは5%のNFTを特別に進呈します。",
      "icon": bookmark
    },
    {
      "number": "03",
      "title": "限定イベントご招待",
      "content": "プラチナ、ゴールド、シルバー会員の皆様に、Mogokの限定イベントへご招待いたします。貴重な体験を共にしましょう。",
      "icon": blockchainIcon
    },
    {
      "number": "04",
      "title": "プラチナ会員特別ディナー",
      "content": "年2回まで、創設チームとの特別ディナーをご用意させて頂きます。。極上の料理と、のびやかな対話をお約束します。",
      "icon": bookmark
    },
    {
      "number": "05",
      "title": "会員専用ニュース＆ホワイトリスト",
      "content": "パイオニア会員様は、2ヶ月に一度の専用ニュースと共に、NFTホワイトリストにも自動的に登録されます。",
      "icon": blockchainIcon
    },
    {
      "number": "06",
      "title": "サイン入り『仮想通貨3.0』",
      "content": "「仮想通貨3.0」を著したカルプレス・マルク氏からの直筆サイン入り本を贈呈します。",
      "icon": bookmark
    },
  ]

  const updateWidth = useCallback(() => {
    if (containerRef.current) {
      setContainerWidth(containerRef.current.offsetWidth)
    }
    setMaxSlides(containerWidth * (data.length / 3))
  }, [containerWidth, data.length])

  const nextSlidesHandler = () => {
    if (slideValue.value < (maxSlides - containerWidth)) {
      setSlideValue(prevValue => ({
        index: prevValue.index + 1,
        value: prevValue.value + containerWidth
      }));
    }
  };

  const prevSlidesHandler = () => {
    if (slideValue.index !== 0) {
      setSlideValue(prevValue => ({
        index: prevValue.index - 1,
        value: prevValue.value - containerWidth
      }));
    }
  };

  const sliderDotsHandler = (index: number) => {
    setSlideValue(prevValue => ({
      index: index,
      value: containerWidth * index
    }));
  }

  const dotsRender = (size) => {
    let slidesLength = 0
    if (size === "lg") {
      slidesLength = Math.ceil(data.length / 3);
    }
    else if (size === "sm") {
      slidesLength = Math.ceil(data.length);
    }
    const dots = Array.from({ length: slidesLength }, () => ({}));

    return dots;
  };
  const dots = dotsRender("lg");
  const dotsMobile = dotsRender("sm");


  useEffect(() => {
    updateWidth();

    window.addEventListener('resize', updateWidth);

    return () => window.removeEventListener('resize', updateWidth);
  }, [updateWidth]);

  useEffect(() => {
    const handleScroll = () => {
      //@ts-ignore
      setScrollLeftValue(containerRef.current.scrollLeft);
    };

    //@ts-ignore
    containerRef.current.addEventListener('scroll', handleScroll);

    return () => {
      //@ts-ignore
      // eslint-disable-next-line
      containerRef.current.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className='flex flex-col gap-9'>
      <div className='hidden lg:flex items-center gap-4'>
        <button
          className={'px-4 py-1 rounded-3xl border transition-all duration-300 ' + (slideValue.value === 0 ? 'border-mogok-light-red cursor-not-allowed' : 'border-white')}
          onClick={prevSlidesHandler}
        >
          <ArrowLongLeftIcon className={'w-8 transition-all duration-300 ' + (slideValue.value === 0 ? 'text-mogok-light-red' : 'text-white')} />
        </button>
        <button
          className={'px-4 py-1 rounded-3xl border transition-all duration-300 ' + (slideValue.value === (maxSlides - containerWidth) ? 'border-mogok-light-red cursor-not-allowed' : 'border-white')}
          onClick={nextSlidesHandler}
        >
          <ArrowLongRightIcon className={'w-8 transition-all duration-300 ' + (slideValue.value === (maxSlides - containerWidth) ? 'text-mogok-light-red' : 'text-white')} />
        </button>
      </div>
      <div ref={containerRef} className='relative overflow-x-auto md:overflow-hidden hideScrollLeft'>
        <div className='flex gap-4   flex-nowrap justify-normal lg:gap-0 lg:flex-nowrap w-max transition-all duration-500' style={{ transform: `translateX(-${slideValue.value}px)` }}>
          {
            containerWidth > 929 ? (
              data.reduce((resultArray, item, index) => {
                const chunkIndex = Math.floor(index / 3);

                if (!resultArray[chunkIndex]) {
                  resultArray[chunkIndex] = [];
                }

                resultArray[chunkIndex].push(item);

                return resultArray;
              }, []).map((chunk, chunkIndex) => (
                <div className={'flex gap-4'} style={{ width: containerWidth + 'px' }} key={chunkIndex}>
                  {chunk.map((card, index) => (
                    <PionnerCard key={index} index={card.number} {...card} />
                  ))}
                </div>
              ))) : (
              data.map((card, index) => {
                return (
                  <PionnerCard key={index} index={card.number} {...card} />
                )
              })
            )
          }

        </div>
      </div>
      <div className='hidden md:flex justify-center gap-2'>
        {
          dots.map((dot, index) => {
            return (
              <button
                key={index}
                className={'transition-all duration-300 w-[10px] h-[10px] rounded-full bg-mogok-deep-red hover:bg-white ' + (slideValue.index === index ? 'bg-white' : '')}
                onClick={() => sliderDotsHandler(index)}
              ></button>
            )
          })
        }
      </div>
      <div className='flex md:hidden justify-center gap-2'>
        {
          dotsMobile.map((dot, index) => {
            return (
              <button
                key={index}
                className={'transition-all duration-300 w-[10px] h-[10px] rounded-full bg-mogok-deep-red hover:bg-white ' + ((scrollLeftValue === index || (scrollLeftValue <= (((index + 1) * 350)) && scrollLeftValue > index * 350)) ? 'bg-white' : '')}
              ></button>
            )
          })
        }
      </div>
    </div >
  )
}

export default PionnerCaroucel
