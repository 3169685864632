import React, { FC } from 'react';
import { motion } from 'framer-motion';
import { ChevronDoubleDownIcon } from '@heroicons/react/24/outline';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import AnimatedImage from '../AnimatedImage/AnimatedImage';
import IntroQuote from '../../components/IntroQuote/IntroQuote';
import Button from '../../components/common/Button/Button';


import opensea from '../../assets/img/opensea.png';
import openseaText from '../../assets/img/opensea-text.png';
import uniswapText from '../../assets/img/uniswap-text.png';
import uniswap from '../../assets/img/uniswap.png';

interface HeroSectionProps {
  isAnimating: boolean;
}

const rotating = {
  initial: { rotate: 360 },
  animate: { rotate: -360 },
}

const HeroSection: FC<HeroSectionProps> = ({ isAnimating }) => {
  const { t } = useTranslation();

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  }

  return (
    <section className='pt-10 lg:pt-24 overflow-hidden'>
      <div className='mogok-container px-4 xl:px-0'>
        <div className='flex justify-between flex-col items-center lg:flex-row gap-12 lg:gap-5'>
          <div className={'flex flex-col transition-all duration-300 ' + (isAnimating ? ' justify-center mb-0' : ' mb-8 md:mb-24')}>
            <div className={'transition-all duration-300 ' + (isAnimating ? ' mb-0' : ' mb-16')}>
              <IntroQuote />
            </div>
            {
              !isAnimating ?
                <span className='text-white font-medium text-sm sm:text-base'>
                  {t('hero', { context: 'paragraph1' })}
                  <br />
                  {t('hero', { context: 'paragraph2' })}
                </span> : <></>
            }
          </div>
          <AnimatedImage animated={isAnimating} />
        </div>
        {
          isAnimating ?
            <motion.div className='flex flex-col lg:flex-row items-center gap-0 md:gap-11 py-16'>
              <motion.div
                animate="initial"
                whileHover="animate"
                className='flex items-center gap-4 w-full sm:w-auto md:gap-9 md:max-w-[525px]'
              >
                <a href='https://opensea.io/collection/mogok' className='flex items-center gap-4 w-full sm:w-auto md:gap-9' target='_blank' rel="noreferrer">
                  <div className='relative w-44 h-44 flex items-center justify-center'>
                    <img className='w-14 md:w-[78px]' src={opensea} alt='Opensea' />
                    <motion.div
                      className='absolute'
                      animate={{ rotate: 360 }}
                      variants={rotating}
                      transition={{ ease: "linear", duration: 6, repeat: Infinity }}
                      whileHover={{ rotate: -360 }}
                    >
                      <img src={openseaText} alt='animated text' />
                    </motion.div>
                  </div>
                  <Button label={`${t('browse_and_buy')} ${t('on_opensea', { ns: 'common' })}`} color='white' size='lg' />
                </a>
              </motion.div>
              <motion.div
                animate="initial"
                whileHover="animate"
                className='flex items-center gap-4 w-full sm:w-auto md:gap-9 md:max-w-[525px]'
              >
                <a href='https://app.uniswap.org/#/tokens/polygon/0xc37cca16ff2db4c82021d3a5bead3796071ecfcb' target='_blank' className='flex items-center gap-4  w-full sm:w-auto md:gap-9 md:max-w-[489px]' rel="noreferrer">
                  <div className='relative w-44 h-44 flex items-center justify-center'>
                    <img className='w-14 md:w-[78px]' src={uniswap} alt='Uniswap' />
                    <motion.div
                      className='absolute'
                      animate={{ rotate: 360 }}
                      variants={rotating}
                      transition={{ ease: "linear", duration: 6, repeat: Infinity }}
                      whileHover={{ rotate: -360 }}
                    >
                      <img src={uniswapText} alt='animated text' />
                    </motion.div>
                  </div>
                  <Button label={`${t('browse_and_buy')} ${t('on_uniswap', { ns: 'common' })}`} color='white' size='lg' />
                </a>
              </motion.div>
            </motion.div> :
            <></>
        }
      </div>
      <div className={'flex transition-all duration-300 bg-mogok-deep-red lg:bg-transparent ' + (isAnimating ? ' hidden lg:flex h-24 justify-center' : ' h-80 px-4')}>
        <motion.div
          className={'flex items-center justify-center lg:justify-end relative w-full lg:w-1/2 lg:pr-9 xl:pr-32' + (isAnimating ? ' hidden' : '')}
        >
          <div className='bg-mogok-deep-red absolute top-0 right-0 bottom-0 w-[1400px] hidden lg:block'></div>
          <Link to='/buy' className='hidden relative max-w-[430px]' onClick={scrollToTop}>
            <Button label={t('hero', { context: 'available_now_for_sale' })} color='white' size='xl' />
          </Link>
        </motion.div>
        <div className={'items-center justify-end' + (isAnimating ? ' flex' : ' w-[556px] mr-auto lg:flex hidden')}>
          <div className='flex flex-col items-center gap-2'>
            <span className='font-semibold text-white'>{t('scroll', { ns: 'common' })}</span>
            <motion.div
              transition={{
                repeat: Infinity,
                ease: "easeOut",
                duration: 0.8
              }}
              animate={{
                y: ['0px', '10px']
              }}
            >
              <ChevronDoubleDownIcon className='text-white w-6 h-6' />
            </motion.div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default React.memo(HeroSection);
