import { FC } from 'react';
import { motion } from 'framer-motion';

import nftCard1 from '../../assets/img/nft-card1.png';
import nftCard2 from '../../assets/img/nft-card2.png';
import nftCard3 from '../../assets/img/nft-card3.png';
import nftCard4 from '../../assets/img/nft-card4.png';
import ruby from '../../assets/img/ruby-image.png';

interface AnimatedImageProps {
  animated: boolean;
}

const AnimatedImage: FC<AnimatedImageProps> = ({ animated }) => {
  return(
    <div className={'relative z-10' + (animated ? ' h-[220px] md:h-[444px] w-[207px] md:w-[417px]' : ' flex items-end pb-7 lg:h-[444px]')}>
      <motion.div className={'absolute shadow z-40' + (animated ? ' w-[125px] md:w-[252px] bottom-0 left-0' : ' w-[141px] lg:w-[178px] -left-7 sm:-left-11 lg:-left-32 -bottom-6')}>
        <img className='w-full h-full' src={nftCard1} alt='NFT Card' />
      </motion.div>
      <motion.div className={'absolute shadow z-30' + (animated ? ' w-[125px] md:w-[252px] left-7 bottom-7 md:left-[62px] md:bottom-[54px]' : ' w-6 lg:w-[44px] -right-2 bottom-[54px] lg:-right-4 lg:bottom-20')}>
        <img className={'transition-all duration-300' + (animated ? ' blur-0' : ' blur-[2px]')} src={nftCard2} alt='NFT Card' />
      </motion.div>
      <motion.div className={'absolute shadow z-20' + (animated ? ' w-[125px] md:w-[252px] top-5 right-5 md:right-10 md:top-10' : ' w-[69px] lg:w-[126px] right-6 -top-8 lg:right-14 lg:top-10')}>
        <img src={nftCard3} alt='NFT Card' />
      </motion.div>
      <motion.div className={'absolute z-10' + (animated ? ' rounded md:rounded-lg w-[125px] md:w-[252px] top-0 right-0 bg-mogok-deep-red' : ' shadow w-10 lg:w-[74px] left-[103px] -top-11 lg:top-8 lg:left-44')}>
        <img className={animated ? 'opacity-0' : 'blur-[2px] opacity-100'} src={nftCard4} alt='NFT Card' />
      </motion.div>
      <div className={animated ? 'opacity-0' : 'opacity-100'}>
        <img className='w-[290px] lg:w-full lg:max-w-lg ' src={ruby} alt='Mogok Ruby' />
      </div>
    </div>
  );
}

export default AnimatedImage;
