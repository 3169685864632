import { atom } from 'recoil';

const MogokRubyTabsState = atom({
  key: 'MogokRubyTabsState',
  default: {
    arrowsState: 'left'
  }
});

export default MogokRubyTabsState;
